// import Sidebar from "./Sidebar";
import Header from "./Header";

const Layout = () => {
  console.log("111111 enteredddd");
  return (
    <div>
      <Header />
      <div>
        <div style={{ display: "flex", marginTop: "0px" }}>
          {/* <Sidebar option={0} /> */}
        </div>
      </div>
    </div>
  );
};

export default Layout;
