export const requestStates = (value) => {
  if (value.toString() === "AWAITING_ACCEPTANCE") {
    return "Awaiting Acceptance";
  } else if (value.toString() === "AWAITING_REPAYMENT") {
    return "Awaiting Repayment";
  } else if (value.toString() === "ACCEPTED") {
    return "Accepted";
  } else if (value.toString() === "AWAITING_DISBURSEMENT") {
    return "Awaiting Disbursement";
  } else if (value.toString() === "CANCELLED") {
    return "Cancelled";
  } else if (value.toString() === "COMPLETED") {
    return "Completed";
  } else if (value.toString() === "REQUEST_CLOSED") {
    return "Request Closed";
  } else if (value.toString() === "REQUEST_DECLINED") {
    return "Request Declined";
  } else if (value.toString() === "REQUEST_OFFER_DECLINED") {
    return "Request Offer Declined";
  } else if (value.toString() === "REQUEST_CREATED") {
    return "Request Created";
  } else if (value.toString() === "DECLINED") {
    return "Declined";
  } else if (value.toString() === "DEFAULT") {
    return "Default";
  } else if (value.toString() === "DISBURSED") {
    return "Disbursed";
  } else if (value.toString() === "DISBURSEMENT") {
    return "Disbursement";
  } else if (value.toString() === "DISBURSEMENT_DONE") {
    return "Disbursement Done";
  } else if (value.toString() === "DISBURSEMENT_CREATED") {
    return "Disbursement Created";
  } else if (value.toString() === "DISBURSEMENT_INITIATED") {
    return "Disbursement Initiated";
  } else if (value.toString() === "DISBURSEMENT_COMPLETED") {
    return "Disbursement Completed";
  } else if (value.toString() === "DISBURSEMENT_PENDING") {
    return "Disbursement Pending";
  } else if (value.toString() === "EXPIRED") {
    return "Expired";
  } else if (value.toString() === "FAILED") {
    return "Failed";
  } else if (value.toString() === "FINANCE_REQUEST_CLOSED") {
    return "Finance Request Closed";
  } else if (value.toString() === "FULLY_PAID") {
    return "Fully Paid";
  } else if (value.toString() === "IN_PROCESS") {
    return "In Process";
  } else if (value.toString() === "INPROCESS") {
    return "In Process";
  } else if (value.toString() === "INITIATED") {
    return "Initiated";
  } else if (value.toString() === "OFFER_ACCEPTED") {
    return "Offer Accepted";
  } else if (value.toString() === "OFFER_PLACED") {
    return "Offer Placed";
  } else if (value.toString() === "OPEN_OFFER") {
    return "Open Offer";
  } else if (value.toString() === "OPEN_REQUEST") {
    return "Open Request";
  } else if (value.toString() === "OVERDUE") {
    return "Overdue";
  } else if (value.toString() === "PARTIALLY_PAID") {
    return "Partially Paid";
  } else if (value.toString() === "PLACED_OFFER_CLOSED") {
    return "Placed Offer Closed";
  } else if (value.toString() === "PLACED_OFFER_DECLINED") {
    return "Placed Offer Declined";
  } else if (value.toString() === "REQUEST_REJECTED") {
    return "Request Rejected";
  } else if (value.toString() === "REQUEST_EXPIRED") {
    return "Request Expired";
  } else if (value.toString() === "AWAITING_OFFER") {
    return "Awaiting Offer";
  } else if (value.toString() === "PENDING_REPAYMENT") {
    return "Pending Repayment";
  } else if (value.toString() === "REPAYMENT_PENDING") {
    return "Repayment Pending";
  } else if (value.toString() === "REQUEST_ACCEPTED") {
    return "Request Accepted";
  } else if (value.toString() === "REQUEST_INPROCESS") {
    return "Request Inprocess";
  } else if (value.toString() === "REPAYMENT_DONE") {
    return "Repayment Done";
  } else if (value.toString() === "REQUEST_OFFER_CLOSED") {
    return "Request Offer Closed";
  } else if (value.toString() === "REQUEST_PROCESSED") {
    return "Request Processed";
  } else if (value.toString() === "REQUEST_PROCESSING") {
    return "Request Processing";
  } else if (value.toString() === "PLACED_OFFER_CLOSED") {
    return "Placed Offer Closed";
  } else if (value.toString() === "REQUEST_RAISED") {
    return "Request Raised";
  } else if (value.toString() === "PROCESSING") {
    return "Processing";
  } else if (value.toString() === "REJECTED") {
    return "Rejected";
  } else if (value.toString() === "REPAYMENT_CREATED") {
    return "Repayment Created";
  } else if (value.toString() === "REPAYMENT_INITIATED") {
    return "Repayment Initiated";
  } else if (value.toString() === "SCHEDULED") {
    return "Scheduled";
  } else if (value.toString() === "SETTLEMENT_CREATED") {
    return "Settlement Created";
  } else if (value.toString() === "SETTLEMENT_COMPLETED") {
    return "Settlement Completed";
  } else if (value.toString() === "PARTICIPANT_SETTLEMENT_CREATED") {
    return "Participant Settlement Created";
  } else if (value.toString() === "PARTICIPANT_SETTLEMENT_COMPLETED") {
    return "Participant Settlement Completed";
  } else if (value.toString() === "REPAYMENT_OVERDUE") {
    return "Repayment Overdue";
  } else if (value.toString() === "PENDING_DISBURSEMENT") {
    return "Pending Disbursement";
  }
};

export const newstates = (value) => {
  if (value.toString() === "NOT_REVIEWED") {
    return "Not Reviewed";
  } else if (value.toString() === "APPROVED") {
    return "Approved";
  } else if (value.toString() === "REJECTED") {
    return "Rejected";
  }
};
export const dateFormat = (date, days) => {
  date.setDate(date.getDate() + days);
  let month = date.toLocaleString("default", { month: "short" }); //months from 1-12
  let day = date.getUTCDate();
  let year = date.getUTCFullYear();
  let hour = date.getHours();
  let minutes = date.getMinutes();
  return month + " " + day + " " + year + ", " + hour + ":" + minutes;
};

const actionDescription = (actionCode) => {
  if (actionCode === "fetch") {
    return "Fetching ....";
  } else if (actionCode === "new") {
    return "Creating a new Placed Offer";
  }
};

export const contextObj = (authContextObj, id) => {
  return {
    context: authContextObj,
    message: {
      id: id,
    },
  };
};
export const contextObjforcrepostapicall = (
  authContextObj,
  id,
  credata,
  individualAssessmentList,
  financeRequest
) => {
  return {
    context: authContextObj,
    anchorTraderUlidId: id,
    cbcreProcess: credata,
    individualAssessmentList: individualAssessmentList,
    financeRequest: financeRequest,
  };
};

export const contextObjforcreapicall = (authContextObj, id, amount) => {
  return {
    context: authContextObj,
    anchorTraderUlidId: id,
    amount: amount,
  };
};
export const contextObjforapicall = (authContextObj, id) => {
  return {
    context: authContextObj,
    message: {
      id: id,
    },
  };
};

export const contextObjForPlacingOffer = (
  authContextObj,
  requestOfferObj,
  placedOfferObj
) => {
  return {
    context: authContextObj,
    message: {
      id: "",
      msgDetails: {
        requestOffer: requestOfferObj,
        placedOffer: placedOfferObj,
      },
    },
  };
};

export const contextObjController = (authContextObj, anchorTraderID) => {
  return {
    context: authContextObj,
    message: {
      id: anchorTraderID,
    },
  };
};

export const profileContextObjController = (
  authContextObj,
  anchorTraderID,
  anchorTraderObj = {}
) => {
  return {
    context: authContextObj,
    message: {
      id: anchorTraderID,
      msgDetails: {
        anchorTrader: anchorTraderObj,
        firmMemberDetailsList: [],
      },
      // ...(searchText ? {query: `${searchText}${!isNaN(searchText) ? `  OR exportID:${searchText}` : ''}`} : {}),
    },
  };
};

export const kycContextObjController = (
  authContextObj,
  anchorTraderID,
  anchorTraderObj = {},
  firmMemberDetailsList,
  allTypesOfFirmsData
) => {
  return {
    context: authContextObj,
    message: {
      id: anchorTraderID,
      msgDetails: {
        anchorTrader: anchorTraderObj,
        firmMemberDetailsList: firmMemberDetailsList,
        partnershipFirm: allTypesOfFirmsData.partnershipFirm,
        propreitorshipFirm: allTypesOfFirmsData.propreitorshipFirm,
        llpFirm: allTypesOfFirmsData.llpFirm,
        limitedCompanyFirm: allTypesOfFirmsData.limitedCompanyFirm,
      },
      // ...(searchText ? {query: `${searchText}${!isNaN(searchText) ? `  OR exportID:${searchText}` : ''}`} : {}),
    },
  };
};

export const panValidateContextObjController = (
  authContextObj,
  anchorTraderID,
  panRequest
) => {
  return {
    context: authContextObj,
    message: {
      id: anchorTraderID,
      msgDetails: {
        panRequest: panRequest,
      },
    },
  };
};

export const generateOTPContextObjController = (
  authContextObj,
  id,
  generateOTPRequest
) => {
  return {
    context: authContextObj,
    message: {
      id: "",
      msgDetails: {
        generateOTPRequest: generateOTPRequest,
      },
    },
  };
};

export const verifyOTPContextObjController = (
  authContextObj,
  id,
  validateOTPRequest
) => {
  return {
    context: authContextObj,
    message: {
      id: "",
      msgDetails: {
        validateOTPRequest: validateOTPRequest,
      },
    },
  };
};

export const presignedContextObjController = (
  authContextObj,
  id,
  documentCode
) => {
  return {
    context: authContextObj,
    message: {
      id: id,
      msgDetails: {
        documentCode: documentCode,
      },
    },
  };
};

export const gstCompanyDetailsContextObjController = (authContextObj, id) => {
  return {
    context: authContextObj,
    message: {
      id: id,
    },
  };
};

export const gstCompanyGenerateOTPContextObjController = (
  authContextObj,
  gstNumber,
  gstUsername
) => {
  return {
    context: authContextObj,
    message: {
      msgDetails: {
        generateGSTOTPRequest: {
          gstin_no: gstNumber,
          gstin_username: gstUsername,
        },
      },
    },
  };
};

export const gstCompanyVerifyOTPContextObjController = (
  authContextObj,
  gstNumber,
  gstUsername,
  gstOTP,
  accessToken
) => {
  return {
    context: authContextObj,
    message: {
      msgDetails: {
        verifyGSTOTPRequest: {
          gstin_no: gstNumber,
          gstin_username: gstUsername,
          gstin_otp: gstOTP,
          access_token: accessToken,
        },
      },
    },
  };
};

export const onAcceptedOfferContextObjController = (
  authContextObj,
  placedOfferUlidId,
  financeRequestUlidId,
  msgDetails
) => {
  return {
    context: authContextObj,
    message: {
      placedOfferUlidId: placedOfferUlidId,
      financeRequestUlidId: financeRequestUlidId,
      msgDetails: msgDetails,
    },
  };
};
