import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import ProfileGSTOTP from "./ProfileGSTOTP";
// import { getGstDetails, getOTP, getToken } from "../Actions";
import { Alert, Button, Snackbar, TextField } from "@mui/material";
import { inputLabelClasses } from "@mui/material/InputLabel";
import CircularProgress from "@mui/material/CircularProgress";
import { useAuth } from "../../../context/auth";
import { controllerAPI } from "../../API/FP/Actions";
import {
  gstCompanyDetailsContextObjController,
  gstCompanyGenerateOTPContextObjController,
} from "../../Common/Constants";

function ProfileGST() {
  const [role, setRole] = useState("seller");
  const [auth, setAuth] = useAuth();
  const [accessTokenAPI, setAccessTokenAPI] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [errorAPi, setErrorApi] = useState(false);

  const authContextObj = {
    transactionDate: new Date().toISOString(),
    userId: auth?.userDetails?.username,
    tenantId: auth?.userDetails?.tenantId,
    cpCode: "1",
  };

  const navigate = useNavigate();
  const [gstId, setGstId] = useState("");
  const [username, setUsername] = useState("");
  const [showOTP, setShowOTP] = useState(false);
  const [otpNotGenerated, setOtpNotGenerated] = useState(false);
  const [allValues, setAllValues] = useState({
    legalName: "",
    tradeName: "",
    constitutionOfBusiness: "",
    administrativeOffice: "",
  });
  useEffect(() => {
    if (gstId === "12345") {
      // debugger;
      setAllValues({
        legalName: "Prime Textiles",
        tradeName: "Prime Textiles",
        constitutionOfBusiness: "Partnership",
        administrativeOffice: "Administrative Office Address",
      });
    } else {
      setAllValues({
        username: "",
        legalName: "",
        tradeName: "",
        constitutionOfBusiness: "",
        administrativeOffice: "",
      });
    }
  }, [gstId]);
  const fetchUserData = async () => {
    setIsLoading(true);
    const constructObj = gstCompanyDetailsContextObjController(
      authContextObj,
      gstId
    );
    const response = await controllerAPI(
      "post",
      auth?.token,
      constructObj,
      "fetch-company-details"
    );
    console.log("1111 response", response);
    try {
      if (response.res.status === 200) {
        const rData = response.res.data.data;
        setIsLoading(false);
        setAllValues({
          ...allValues,
          legalName: rData.company?.lgnm,
          tradeName: rData.company?.tradenam,
          constitutionOfBusiness: rData.company?.ctb,
          administrativeOffice: rData.company?.ctj,
        });
      }
    } catch (err) {
      setIsLoading(false);
      setErrorApi(true);
      console.log("Error", err);
    }
  };
  //   const verifyOTP = async () => {
  //     const oResponse = await getOTP(gstId, username);
  //     try {
  //       //console.log(response);
  //       if (oResponse.res.status === 200) {
  //         // setMessage("try again after some time");
  //         // setModalClose(true);
  //         const rData = oResponse.res.data;
  //         setIsLoading(false);
  //         setAllValues({
  //           ...allValues,
  //           legalName: rData.company?.lgnm,
  //           tradeName: rData.company?.tradenam,
  //           constitutionOfBusiness: rData.company?.ctb,
  //           administrativeOffice: rData.company?.ctj,
  //         });
  //       }
  //     } catch (err) {
  //       setIsLoading(false);

  //       console.log("Error", err);
  //     }
  //   };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const constructObj = gstCompanyGenerateOTPContextObjController(
      authContextObj,
      gstId,
      username
    );
    const response = await controllerAPI(
      "post",
      auth?.token,
      constructObj,
      "generate-gst-otp"
    );
    try {
      console.log(response);
      if (response.res.status === 200) {
        setAccessTokenAPI(response.res.data.data.access_token);
        response.res.data?.data?.data?.error
          ? setOtpNotGenerated(true)
          : setShowOTP(true);
        // setShowOTP(true);
      }
    } catch (err) {
      setIsLoading(false);

      console.log("Error", err);
    }
  };
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setErrorApi(false);
  };
  return (
    <div className="main-container">
      <div className="mainDiv">
        <div>
          {isLoading && (
            <CircularProgress
              style={{ position: "absolute", left: "50%", top: "50%" }}
            />
          )}
          {/* 
          <h2>Get API Access Consent</h2>
          <h4>
            {!showOTP
              ? "Provide your GST Details"
              : "Enter the OTP sent to your registered mobile number"}
          </h4> */}
        </div>
        {!showOTP && (
          <div>
            <form className="form" onSubmit={handleSubmit}>
              <div className="main-signinGST">
                <div className="formwrap">
                  <div
                    style={{
                      paddingBottom: "10px",
                    }}
                  >
                    <div style={{ display: "flex" }}>
                      <TextField
                        id="gstId"
                        inputProps={{ style: { fontSize: 14 } }}
                        InputLabelProps={{
                          sx: {
                            color: "#4A3AFF",
                            fontWeight: "bold",
                            [`&.${inputLabelClasses.shrink}`]: {
                              color: "#4A3AFF",
                            },
                          },
                        }}
                        label="GST ID"
                        value={gstId}
                        onChange={(e) => setGstId(e.target.value)}
                        variant="standard"
                      />
                      <Button
                        style={{
                          padding: "2px 10px",
                          height: "fit-content",
                          color: "white",
                          backgroundColor: "#4A3AFF",
                          border: "none",
                          marginLeft: "8px",
                          borderRadius: "3px",
                          textTransform: "none",
                          alignSelf: "end",
                        }}
                        onClick={() => fetchUserData()}
                      >
                        Validate GST
                      </Button>
                    </div>
                  </div>

                  <div
                    style={{
                      paddingBottom: "10px",
                    }}
                  >
                    <TextField
                      id="legalname"
                      inputProps={{ style: { fontSize: 14 } }}
                      InputLabelProps={{
                        sx: {
                          color: "#4A3AFF",
                          fontWeight: "bold",
                          [`&.${inputLabelClasses.shrink}`]: {
                            color: "#4A3AFF",
                          },
                        },
                      }}
                      label="Legal Name of Business"
                      value={allValues.legalName}
                      // onChange={(e) => setPassword(e.target.value)}
                      style={{ width: "100%" }}
                      variant="standard"
                      disabled
                    />
                  </div>
                  <div
                    style={{
                      paddingBottom: "10px",
                    }}
                  >
                    <TextField
                      id="tradename"
                      inputProps={{ style: { fontSize: 14 } }}
                      InputLabelProps={{
                        sx: {
                          color: "#4A3AFF",
                          fontWeight: "bold",
                          [`&.${inputLabelClasses.shrink}`]: {
                            color: "#4A3AFF",
                          },
                        },
                      }}
                      label="Trade Name"
                      value={allValues.tradeName}
                      // onChange={(e) => setPassword(e.target.value)}
                      style={{ width: "100%" }}
                      variant="standard"
                      disabled
                    />
                  </div>
                  <div
                    style={{
                      paddingBottom: "10px",
                    }}
                  >
                    <TextField
                      id="constitution"
                      inputProps={{ style: { fontSize: 14 } }}
                      InputLabelProps={{
                        sx: {
                          color: "#4A3AFF",
                          fontWeight: "bold",
                          [`&.${inputLabelClasses.shrink}`]: {
                            color: "#4A3AFF",
                          },
                        },
                      }}
                      label="Constitution of Business"
                      value={allValues.constitutionOfBusiness}
                      // onChange={(e) => setPassword(e.target.value)}
                      style={{ width: "100%" }}
                      variant="standard"
                      disabled
                    />
                  </div>
                  <div
                    style={{
                      paddingBottom: "10px",
                    }}
                  >
                    <TextField
                      id="administrative"
                      inputProps={{ style: { fontSize: 14 } }}
                      InputLabelProps={{
                        sx: {
                          color: "#4A3AFF",
                          fontWeight: "bold",
                          [`&.${inputLabelClasses.shrink}`]: {
                            color: "#4A3AFF",
                          },
                        },
                      }}
                      label="Administrative Office"
                      value={allValues.administrativeOffice}
                      // onChange={(e) => setPassword(e.target.value)}
                      style={{ width: "100%" }}
                      variant="standard"
                      disabled
                    />
                  </div>
                </div>
              </div>
              <div className="main-signinGST">
                <div className="formwrap">
                  {/* <form className="form" onSubmit={handleSubmit}> */}
                  <div>
                    <TextField
                      id="username"
                      inputProps={{ style: { fontSize: 14 } }}
                      InputLabelProps={{
                        sx: {
                          color: "#4A3AFF",
                          fontWeight: "bold",
                          [`&.${inputLabelClasses.shrink}`]: {
                            color: "#4A3AFF",
                          },
                        },
                      }}
                      label="GST Username"
                      value={username}
                      onChange={(e) => setUsername(e.target.value)}
                      style={{ width: "100%" }}
                      variant="standard"
                      // disabled
                      required
                    />
                  </div>
                  {otpNotGenerated && (
                    <div
                      style={{
                        color: "red",
                        textAlign: "center",
                        marginTop: "10px",
                      }}
                    >
                      OTP Not Generated
                    </div>
                  )}
                  <div style={{ textAlign: "center" }}>
                    <button
                      type="submit"
                      style={{
                        padding: "8px",
                        color: "white",
                        backgroundColor: "green",
                        border: "none",
                        marginTop: "15px",
                        borderRadius: "5px",
                        opacity: allValues.legalName !== "" ? "1" : "0.5",
                      }}
                      disabled={allValues.legalName !== "" ? false : true}
                      // onClick={() => fetchOTP()}
                    >
                      Generate OTP
                    </button>
                  </div>
                  {/* </form> */}
                </div>
              </div>
            </form>
            <Snackbar
              open={errorAPi}
              autoHideDuration={6000}
              onClose={handleClose}
              anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
            >
              <Alert
                onClose={handleClose}
                severity="error"
                sx={{ width: "100%" }}
              >
                Invalid GST ID !
              </Alert>
            </Snackbar>
          </div>
        )}

        {showOTP && (
          <ProfileGSTOTP
            gstId={gstId}
            gstUsername={username}
            accessToken={accessTokenAPI}
            authContextObj={authContextObj}
          />
        )}
      </div>
    </div>
  );
}

export default ProfileGST;
