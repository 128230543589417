import React, { useCallback, useEffect, useState } from "react";
import { styled } from "styled-components";
// import Footer from "../Common/Footer";
import { useLocation, useNavigate } from "react-router-dom";
import {
  Breadcrumbs,
  CircularProgress,
  Divider,
  Link,
  Typography,
} from "@mui/material";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import ProfileDetails from "./ProfileDetails";
import ProfileKYC from "./ProfileKYC";
import ProfileERP from "./ProfileERP";
import ProfileGST from "./ProfileGST";
import {
  contextObjController,
  presignedContextObjController,
} from "../../Common/Constants";
import { controllerAPI } from "../../API/FP/Actions";
import { useAuth } from "../../../context/auth";

function ProfileIndividual({
  creditScoreReportCheck,
  setCreditScoreReportCheck,
}) {
  const [value, setValue] = useState("1");
  const [profileDetailsApiData, setProfileDetailsApiData] = useState([]);
  const [anchorTraderPartnersData, setAnchorTraderPartnersData] = useState([]);
  const [gstinList, setgstinList] = useState([]);
  const [allTypesOfFirmsData, setallTypesOfFirmsData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [presignedURl, setPresignedURL] = useState("");
  const [auth, setAuth] = useAuth();
  const [showSingleGst, setShowSingleGst] = useState(false);
  const [selectedKycMasterDetails, setSelectedKycMasterDetails] = useState([]);
  const [disableState, setDisableState] = useState(true);
  const [gstDisable, setGstDisable] = useState(false);
  let navigate = useNavigate();
  const authContextObj = {
    transactionDate: new Date().toISOString(),
    userId: auth?.userDetails?.username,
    tenantId: auth?.userDetails?.tenantId,
    personaCode: 1,
  };

  // console.log("111111111111111111111111111111111", authContextObj, auth);
  const [anchorTraderID, setAnchorTraderID] = useState(
    auth?.userDetails?.personaIds["ANCHOR_TRADER-1"]
  );
  const fetchToken = useCallback(async () => {
    setIsLoading(true);
    try {
      const constructObj = contextObjController(authContextObj, anchorTraderID);
      const profileDetailsResponse = await controllerAPI(
        "post",
        auth?.token,
        constructObj,
        "get-at-profile-details"
      );
      const constructObj1 = presignedContextObjController(
        authContextObj,
        anchorTraderID,
        "101"
      );
      const presignedURLResponse = await controllerAPI(
        "post",
        auth?.token,
        constructObj1,
        "get-presigned-url"
      );
      if (profileDetailsResponse?.response?.status === 401) {
        setAuth({
          ...auth,
          user: null,
          token: "",
          userDisplayName: "",
          userDetails: null,
        });
        localStorage.removeItem("auth");
        navigate("/creditbazaar/");
      }
      if (profileDetailsResponse.res.status === 200) {
        setDisableState(false);
        setProfileDetailsApiData(
          profileDetailsResponse?.res?.data?.data?.anchorTrader
        );
        setAnchorTraderPartnersData(
          profileDetailsResponse?.res?.data?.data?.firmMemberDetailsList
        );
        setgstinList(profileDetailsResponse?.res?.data?.data?.gstinList);
        if (profileDetailsResponse?.res?.data?.data?.gstinList.length === 0) {
          setGstDisable(true);
        } else {
          setGstDisable(false);
        }
        setallTypesOfFirmsData(profileDetailsResponse.res.data.data);
      }
      if (presignedURLResponse.res.status === 200) {
        setPresignedURL(presignedURLResponse.res.data.data.presigned_url);
      }
    } catch (err) {
      console.log("Error", err);
    } finally {
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    if (value !== "2" && value !== "3") {
      fetchToken();
    }
  }, [value]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <>
      <div>
        {isLoading && (
          <CircularProgress
            style={{
              position: "absolute",
              left: "50%",
              top: "50%",
              zIndex: "1100",
            }}
          />
        )}
        <Finance1>
          <div>
            <div
              role="presentation"
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
                color: "#4A3AFF",
                marginTop: "50px",
              }}
            >
              <div className="header">
                <h3 style={{ margin: "0px" }} className="section-heading">
                  Profile
                </h3>
                <Typography style={{ fontSize: "15px" }}>
                  Please fill in the below details
                </Typography>
              </div>
            </div>
          </div>
        </Finance1>
        <RequestInformation>
          <div>
            {/* <div className="card">
          <div className="card-body profile-card pt-4 d-flex flex-column align-items-center">
            <img src={pimg} alt="Profile" className="img" />
            <h2>Rishi Kumar</h2>
            <h3>Anchor Trader</h3>
          </div>
        </div> */}
            <Divider
              style={{ margin: "15px 0px", backgroundColor: "#4A3AFF" }}
            />

            <div className="allcont">
              <Box sx={{ width: "100%", typography: "body1" }}>
                <TabContext value={value}>
                  <Box sx={{ borderColor: "divider" }}>
                    <TabList
                      onChange={handleChange}
                      aria-label="lab API tabs example"
                      TabIndicatorProps={{
                        style: {
                          backgroundColor: "#E59C3C",
                        },
                      }}
                    >
                      <Tab
                        label=" Details"
                        value="1"
                        style={{
                          textTransform: "none",
                          fontWeight: "600",
                          color: "#4A3AFF",
                        }}
                      />
                      <Tab
                        label="GST Consent"
                        value="2"
                        style={{
                          textTransform: "none",
                          fontWeight: "600",
                          color: "#4A3AFF",
                          opacity:
                            disableState || gstDisable === true ? "0.5" : "1",
                        }}
                        disabled={disableState || gstDisable}
                      />
                      <Tab
                        label="KYC"
                        value="3"
                        style={{
                          textTransform: "none",
                          fontWeight: "600",
                          color: "#4A3AFF",
                          opacity:
                            disableState || gstDisable === true ? "0.5" : "1",
                        }}
                        disabled={disableState || gstDisable}
                      />
                      <Tab
                        label=" ERP access"
                        value="4"
                        style={{
                          textTransform: "none",
                          fontWeight: "600",
                          color: "#4A3AFF",
                          opacity:
                            disableState || gstDisable === true ? "0.5" : "1",
                        }}
                        disabled={disableState || gstDisable}
                      />
                    </TabList>
                  </Box>
                  <TabPanel value="1">
                    <ProfileDetails
                      profileDetailsApiData={profileDetailsApiData}
                      firmMemberDetailsList={anchorTraderPartnersData}
                      allTypesOfFirmsData={allTypesOfFirmsData}
                      presignedURl={presignedURl}
                      isLoading={isLoading}
                      setIsLoading={setIsLoading}
                      gstinList={gstinList}
                      setValue={setValue}
                      setSelectedKycMasterDetails={setSelectedKycMasterDetails}
                      setShowSingleGst={setShowSingleGst}
                      showSingleGst={showSingleGst}
                    />
                  </TabPanel>
                  <TabPanel value="2">
                    <ProfileGST gstinList={gstinList} />
                  </TabPanel>
                  <TabPanel value="3">
                    <ProfileKYC
                      profileDetailsApiData={profileDetailsApiData}
                      firmMemberDetailsList={anchorTraderPartnersData}
                      allTypesOfFirmsData={allTypesOfFirmsData}
                      gstinList={gstinList}
                      isLoading={isLoading}
                      setIsLoading={setIsLoading}
                      selectedKycMasterDetails={selectedKycMasterDetails}
                      setShowSingleGst={setShowSingleGst}
                      showSingleGst={showSingleGst}
                    />
                  </TabPanel>
                  <TabPanel value="4">
                    <ProfileERP
                      profileDetailsApiData={profileDetailsApiData}
                      firmMemberDetailsList={anchorTraderPartnersData}
                      allTypesOfFirmsData={allTypesOfFirmsData}
                      gstinList={gstinList}
                      isLoading={isLoading}
                      setIsLoading={setIsLoading}
                    />
                  </TabPanel>
                </TabContext>
              </Box>
            </div>
          </div>
        </RequestInformation>
      </div>
      {/* <div style={{ marginTop: "30vh" }}>
        <Footer />
      </div> */}
    </>
  );
}

export default ProfileIndividual;

const Finance1 = styled.div`
  .header {
    font-size: 18px;
    margin-top: 15px;
    // margin-left: 10px;
    // display: flex;
  }
  .report-button {
    border-radius: 2px;
    background-color: white;
    color: #4a3aff;
    border: none;
    padding: 10px 20px;
    cursor: pointer;
    height: fit-content;
    margin-right: 25px;
    border: 1px solid rgba(0, 0, 128, 0.2);
    font-size: 11px;
  }
`;

const RequestInformation = styled.div`
  .content {
    display: flex;
    margin-top: 20px;
    padding: 20px;
  }

  .table-container {
    margin-top: 20px;
  }
  table {
    width: 100%;
    border-collapse: collapse;
    border-left: 1px solid rgba(0, 0, 128, 0.2);
    border-right: 1px solid rgba(0, 0, 128, 0.2);
    border-top: 1px solid rgba(0, 0, 128, 0.2);
    // color: #4a3aff;
    border-radius: 5px;

    border-style: hidden; /* hide standard table (collapsed) border */
    box-shadow: 0 0 0 1px rgba(0, 0, 128, 0.2);
  }
  table tr:last-child td:first-child {
    // border: 2px solid orange;
    // border-bottom-left-radius: 10px;
  }

  table tr:last-child td:last-child {
    // border: 2px solid green;
    // border-bottom-right-radius: 10px;
    // border-radius: 0px 0px 0px 10px;
  }
  th,
  td {
    padding: 10px;
    text-align: left;
    border-bottom: 1px solid rgba(0, 0, 128, 0.2);
    border-right: 1px solid rgba(0, 0, 128, 0.2);
  }
  th {
    // background-color: rgba(0, 0, 128, 0.2);
    // font-weight: bold;
    color: rgba(97, 94, 131, 1);
    // width: 55%;
  }
  .main-content {
    margin-top: 30px;
  }
`;
