import React, { useEffect, useState } from "react";
import { styled } from "styled-components";
import Footer from "../Common/Footer";
import { NumericFormat } from "react-number-format";
import {
  CreateRequestsInvoicesMockData,
  CreateRequestsInvoicesSelectedMockData,
} from "../MockData/AnchorTraderMock";
import { useLocation, useNavigate } from "react-router-dom";
import {
  Breadcrumbs,
  CircularProgress,
  Divider,
  Input,
  Link,
  Typography,
} from "@mui/material";
import { callAPI, controllerAPI, getDisbursementData } from "../API/FP/Actions";
import {
  contextObj,
  contextObjforcreapicall,
  contextObjforcrepostapicall,
  dateFormat,
} from "../Common/Constants";
import { useAuth } from "../../context/auth";
import Pagenotfound from "../Common/pagenotfound";
import { CommentBankSharp, TextFormat } from "@mui/icons-material";
import { fontFamily, fontSize } from "@mui/system";
<script src="https://cdn.lordicon.com/lordicon.js"></script>;

function AnchorTraderRequestInformation() {
  const navigate = useNavigate();
  const { state } = useLocation();
  const [auth, setAuth] = useAuth();
  const [requiredAmount, setRequiredAmount] = useState(null);
  const [showTable, setShowTable] = useState(true);
  const [apicreatereqdata, setapidata] = useState([]);
  const [apicreatereqdatacre, setapidatacre] = useState([]);
  const [apicreatereqdatafinance, setapidatafinancereq] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [norecord, setnorecord] = useState(false);
  const [showFetchingMessage, setShowFetchingMessage] = useState(false);
  const [dataLoaded, setdataloaded] = useState(false);
  const [spinnerLoading, setIsspinnerLoading] = useState(false);
  const [anchorTraderID, setAnchorTraderID] = useState(
    auth?.userDetails?.personaIds["ANCHOR_TRADER-1"]
  );
  const textContent = "  Enter Required Amount To Get Invoice Suggestions ";
  const maxCharacters = 49;
  const [text, setText] = useState("");
  const [count, setcount] = useState(0);
  const ariaLabel = { "aria-label": "description" };

  function addDays(date, days) {
    date.setDate(date.getDate() + days);
    let month = date.toLocaleString("default", { month: "short" });
    let day = date.getUTCDate();
    let year = date.getUTCFullYear();
    return `${month} ${day}, ${year}`;
  }

  const authContextObjForATREQ = {
    transactionDate: new Date().toISOString(),
    userId: auth?.userDetails?.username,
    tenantId: auth?.userDetails?.tenantId,
    cpCode: 1,
    clientCode: 1500,
  };

  useEffect(() => {
    let index = 0;

    const interval = setInterval(() => {
      if (index < textContent.length && index < maxCharacters) {
        setText((prevText) => prevText + textContent[index]);
        index++;
      } else {
        clearInterval(interval);
      }
    }, 50);

    return () => clearInterval(interval);
  }, []);

  const postATReqData = async (
    credata,
    individualAssessmentList,
    financeRequest
  ) => {
    setIsspinnerLoading(true);
    try {
      setIsspinnerLoading(true);
      const constructObj = contextObjforcrepostapicall(
        authContextObjForATREQ,
        anchorTraderID,
        credata,
        individualAssessmentList,
        financeRequest
      );
      const ATReqresp = await controllerAPI(
        "post",
        auth?.token,
        constructObj,
        "cb-cre-phase-2"
      );
      if (ATReqresp.res.status === 200) {
        setIsspinnerLoading(false);
        setdataloaded(true);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      setnorecord(true);
    } finally {
      setIsspinnerLoading(false);
      setShowFetchingMessage(false);
      navigate("/creditbazaar/finance-request");
    }
  };
  const fetchATReqdata = async (amount) => {
    setIsLoading(true);
    try {
      setIsLoading(true);
      const constructObj = contextObjforcreapicall(
        authContextObjForATREQ,
        anchorTraderID,
        amount
      );
      const ATReqresp = await controllerAPI(
        "post",
        auth?.token,
        constructObj,
        "cb-cre-phase-1"
      );
      if (ATReqresp.res.status === 200) {
        setapidata(ATReqresp.res.data.data);
        setapidatacre(ATReqresp.res.data.data.cbcreProcess);
        setapidatafinancereq(ATReqresp.res.data.data.financeRequest);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const preventMinus = (e) => {
    if (e.code === "Minus") {
      e.preventDefault();
    }
  };
  const handleCheckboxChange = (invoiceId) => {
    setapidata((prevData) => {
      const updatedData = prevData.individualAssessmentList.map((invoice) => {
        if (invoice.invoiceId === invoiceId) {
          return {
            ...invoice,
            isSelected: !invoice.isSelected,
          };
        }
        return invoice;
      });
      return { ...prevData, individualAssessmentList: updatedData };
    });
  };

  // Calculate total amount selected
  const totalAmountSelected = apicreatereqdata.individualAssessmentList?.reduce(
    (accumulator, currentValue) =>
      currentValue.isSelected
        ? accumulator + currentValue.invoiceAmount
        : accumulator,
    0
  );

  const handleSubmit = () => {
    console.log(
      "Submitted invoices:",
      setShowFetchingMessage(false),
      apicreatereqdata.individualAssessmentList
    );
    postATReqData(
      apicreatereqdatacre,
      apicreatereqdata.individualAssessmentList,
      apicreatereqdatafinance
    );
  };

  return (
    <>
      <Finance1>
        <div>
          <div
            role="presentation"
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              color: "#4A3AFF",
              marginTop: "30px",
            }}
          >
            <div className="header">
              <h3 style={{ margin: "0px" }} className="section-heading">
                Request for finance
              </h3>
              <Typography>
                To initiate a finance request, below is a list of active trades.
                Choose the trades as per your financing requirements.
              </Typography>
            </div>
            <div className="header">
              <Typography>
                <div className="removeArrow">
                  <Input
                    inputProps={ariaLabel}
                    value={requiredAmount}
                    placeholder="Enter Required Amount"
                    type="number"
                    min="0"
                    InputProps={{ inputProps: { min: 0 } }}
                    autoFocus
                    sx={{
                      "& input::placeholder": {
                        fontSize: "15px",
                        fontWeight: "bold",
                      },
                    }}
                    disabled={isLoading}
                    onKeyPress={preventMinus}
                    onChange={(e) => {
                      setRequiredAmount(e.target.value);
                    }}
                  />
                  <button
                    style={{
                      borderRadius: "2px",
                      backgroundColor: "#4A3AFF",
                      color: "white",
                      border: "none",
                      padding: "7px 20px",
                      marginLeft: "10px",
                      cursor: "pointer",
                      borderRadius: "2px",
                      height: "fit-content",
                      opacity:
                        !isLoading && requiredAmount && requiredAmount !== "0"
                          ? "1"
                          : "0.5",
                    }}
                    disabled={
                      !isLoading && requiredAmount && requiredAmount !== "0"
                        ? false
                        : true
                    }
                    onClick={() => {
                      const longAmount = parseFloat(requiredAmount);
                      fetchATReqdata(longAmount);
                      setcount(count + 1);

                      console.log("clicked this many times,", count);
                    }}
                  >
                    Request Finance
                  </button>
                </div>
              </Typography>
            </div>
          </div>
        </div>
      </Finance1>

      {isLoading ? (
        <div
          className="loading-container"
          style={{
            height: "50vh",
            display: "flex",
            justifyContent: "center",
            position: "relative",
            zIndex: "1",
            alignItems: "center",
            flexDirection: "column",
            textAlign: "center",
          }}
        >
          <lord-icon
            src="https://cdn.lordicon.com/cosvjkbu.json"
            trigger="loop"
            delay="400"
            colors="primary:#9cc2f4,secondary:#646e78,tertiary:#3a3347"
            style={{ width: "150px", height: "150px" }}
          ></lord-icon>
          <p>Fetching the best offers for you</p>
        </div>
      ) : (
        <>
          {apicreatereqdata.individualAssessmentList &&
          apicreatereqdata.individualAssessmentList.length > 0 ? (
            <Dash className="body-text">
              {showTable && (
                <div className="main-content">
                  <div className="record-details">
                    {isLoading ? (
                      <></>
                    ) : (
                      <table>
                        <thead>
                          <tr>
                            <th>Date</th>
                            <th>Invoice Number</th>
                            <th>Trade Partner Name</th>
                            <th>Amount (INR)</th>
                            <th>Term (Days)</th>
                            <th>Trade Partner Review</th>
                            <th>
                              {/* <input type="checkbox" className="clickable" /> */}
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {spinnerLoading ? (
                            <CircularProgress
                              style={{
                                position: "absolute",
                                left: "50%",
                                top: "50%",
                              }}
                            />
                          ) : (
                            <>
                              {apicreatereqdata.individualAssessmentList.map(
                                (invoice, index) => (
                                  <tr key={index}>
                                    <td>
                                      {dateFormat(
                                        new Date(invoice?.invDate),
                                        0
                                      ) || "-"}
                                    </td>
                                    <td>{invoice.invoiceId || "-"}</td>
                                    <td>{invoice.tradePartnerName || "-"}</td>
                                    <td>
                                      <NumericFormat
                                        displayType="text"
                                        value={invoice.invoiceAmount}
                                        thousandsGroupStyle="lakh"
                                        thousandSeparator=","
                                      />
                                    </td>
                                    <td>{invoice.term || "-"}</td>
                                    <td>
                                      <span
                                        style={{
                                          padding: "2px 15px",
                                          backgroundColor:
                                            invoice.tradePartnerConfirmation ===
                                            "Approved"
                                              ? "rgba(216, 247, 230, 1)"
                                              : "rgba(74, 58, 255, 0.2)",
                                          color:
                                            invoice.tradePartnerConfirmation ===
                                            "Approved"
                                              ? "#00692A"
                                              : "#4A3AFF",
                                          borderRadius: "100px",
                                          display: "block",
                                          textAlign: "center",
                                        }}
                                      >
                                        {invoice.tradePartnerConfirmation ===
                                        "Approved"
                                          ? invoice.tradePartnerReview
                                          : "Not approved"}
                                      </span>
                                    </td>
                                    <td>
                                      <input
                                        type="checkbox"
                                        className="clickable"
                                        checked={invoice.isSelected || false}
                                        onChange={() =>
                                          handleCheckboxChange(
                                            invoice.invoiceId
                                          )
                                        }
                                      />
                                    </td>
                                  </tr>
                                )
                              )}
                            </>
                          )}
                        </tbody>
                      </table>
                    )}
                  </div>

                  {requiredAmount && (
                    <div style={{ display: "flex" }}>
                      <div
                        style={{
                          marginTop: "15px",
                          textAlign: "center",
                          fontStyle: "italic",
                        }}
                      >
                        Requested amount:{" "}
                        <span style={{ fontWeight: "bold" }}>
                          INR{" "}
                          <NumericFormat
                            displayType="text"
                            value={requiredAmount}
                            thousandsGroupStyle="lakh"
                            thousandSeparator=","
                          />
                        </span>
                      </div>

                      <div
                        style={{
                          marginTop: "15px",
                          marginLeft: "20px",
                          textAlign: "center",
                          fontStyle: "italic",
                        }}
                      >
                        Total selected trade amount:{" "}
                        <span style={{ fontWeight: "bold" }}>
                          INR{" "}
                          <NumericFormat
                            displayType="text"
                            value={totalAmountSelected}
                            thousandsGroupStyle="lakh"
                            thousandSeparator=","
                          />
                        </span>
                      </div>

                      <div
                        style={{
                          marginTop: "15px",
                          marginLeft: "20px",
                          textAlign: "center",
                          fontStyle: "italic",
                        }}
                      >
                        Tenure:{" "}
                        <span style={{ fontWeight: "bold" }}>
                          <NumericFormat
                            displayType="text"
                            value={"-"}
                            thousandsGroupStyle="lakh"
                            thousandSeparator=","
                          />{" "}
                          days
                        </span>
                      </div>
                    </div>
                  )}

                  <div style={{ color: "#4A3AFF" }}>
                    If you find the tenure or trades unsatisfactory, please
                    re-enter the amount again.
                  </div>

                  <div style={{ marginTop: "15px", textAlign: "center" }}>
                    <button
                      style={{
                        borderRadius: "2px",
                        backgroundColor: "#4A3AFF",
                        color: "white",
                        border: "none",
                        padding: "10px 20px",
                        cursor: "pointer",
                        opacity:
                          apicreatereqdata.length !== 0 &&
                          totalAmountSelected === 0
                            ? "0.5"
                            : "1",
                      }}
                      onClick={() => totalAmountSelected > 0 && handleSubmit()}
                    >
                      Confirm
                    </button>
                  </div>
                </div>
              )}
            </Dash>
          ) : (
            <>
              {count > 0 ? (
                <div>
                  <Pagenotfound />
                </div>
              ) : (
                <>
                  <div
                    style={{
                      height: "50vh",
                      display: "flex",
                      justifyContent: "center",
                      position: "relative",
                      zIndex: "1",
                      alignItems: "center",
                      flexDirection: "column",
                      textAlign: "center",
                    }}
                  >
                    <svg
                      width="180px"
                      height="180px"
                      viewBox="0 0 64 64"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="1.5"
                      style={{ animation: "bounce 2s infinite" }}
                    >
                      <style>
                        {`
          @keyframes bounce {
            0%, 20%, 50%, 80%, 100% {
              transform: translateY(0);
            }
            40% {
              transform: translateY(-10px);
            }
            60% {
              transform: translateY(-5px);
            }
          }
          .primary { stroke: #848484; }
          .secondary { fill: #ebe6ef; }
          .tertiary { fill: #66a1ee; }
        `}
                      </style>
                      <rect
                        x="12"
                        y="4"
                        width="40"
                        height="56"
                        rx="4"
                        ry="4"
                        className="secondary"
                      />
                      <line
                        x1="20"
                        y1="12"
                        x2="44"
                        y2="12"
                        className="primary"
                      />
                      <line
                        x1="20"
                        y1="20"
                        x2="44"
                        y2="20"
                        className="primary"
                      />
                      <line
                        x1="20"
                        y1="28"
                        x2="44"
                        y2="28"
                        className="primary"
                      />
                      <line
                        x1="20"
                        y1="36"
                        x2="32"
                        y2="36"
                        className="primary"
                      />
                      <line
                        x1="20"
                        y1="44"
                        x2="32"
                        y2="44"
                        className="primary"
                      />
                      <line
                        x1="20"
                        y1="52"
                        x2="32"
                        y2="52"
                        className="primary"
                      />
                      <text x="35" y="50" className="tertiary" fontSize="20px">
                        ₹
                      </text>
                    </svg>
                    <span
                      style={{ fontFamily: "YourFontFamily", fontSize: "25px" }}
                    >
                      {text.split("").map((char, index) => (
                        <span key={index} className="fade-in">
                          {char}
                        </span>
                      ))}
                    </span>
                  </div>
                </>
              )}
            </>
          )}
        </>
      )}

      {/* <div style={{ marginTop: "30vh" }}>
              <Footer />
          </div> */}
    </>
  );
}

export default AnchorTraderRequestInformation;

const Finance1 = styled.div`
  .custom-link:hover {
    color: #4a3aff;
  }
  .fade-in {
    opacity: 0;
    display: inline-block;
    animation: fadeIn 0.5s forwards;
  }

  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  .btn {
    color: white;
    background-color: #007bff;
    padding: 10px 20px;
    text-decoration: none;
    border-radius: 5px;
    display: inline-block;
    margin-left: 20px;
    font-size: 20px;
    // margin-top: 20vh;
  }
  .btn1 {
    color: white;
    background-color: orange;
    padding: 10px 20px;
    text-decoration: none;
    font-size: 20px;
    border-radius: 5px;
    display: inline-block;
    margin-left: 20px;
  }
  .btn:hover {
    background-color: #0056b3;
  }

  .content {
    margin-left: 20px;
    margin-top: 20px;
    display: flex;
  }
  .offer-card {
    border: 2px solid black;
    // width: 170%;
    margin-bottom: 20px;
  }
  .lender-details {
    margin-left: 40px;
  }

  .header {
    font-size: 18px;
    margin-top: 15px;
  }

  .table-container {
    margin-top: 20px;
  }
  table {
    // width: 300%;
    border-collapse: collapse;
    border-left: 1px solid #dddddd;
    border-right: 1px solid #dddddd;
    border-top: 1px solid #dddddd;
  }
  th,
  td {
    padding: 10px;
    text-align: left;
    border-bottom: 1px solid #dddddd;
  }
  th {
    background-color: #f8f9fa;
    font-weight: bold;
  }
  .search-bar {
    display: flex;
    margin-bottom: 20px;
    margin-right: 10px;
  }
  .search-input {
    flex: 1;
    padding: 8px;
    border: 1px solid #dddddd;
    border-radius: 4px 0 0 4px;
    font-size: 14px;
    align-items: center;
  }
  .search-button {
    background-color: #007bff;
    color: #ffffff;
    border: none;
    padding: 8px;
    border-radius: 0 4px 4px 0;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  .search-button:hover {
    background-color: #0056b3;
  }
  .popup {
    position: relative;
    display: inline-block;
    cursor: pointer;
  }
  .popup .popuptext {
    width: 700px;
    visibility: hidden;
    background-color: #fff;
    color: #000;
    text-align: center;
    border-radius: 6px;
    padding: 8px 0;
    position: absolute;
    z-index: 1;
    left: 50%;
    margin-left: -80px;
    overflow-y: auto;
    border-width: 3px;
    border-style: solid;
    border-color: #000;
  }
  .popup .popuptext::after {
    content: "";
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: #555 transparent transparent transparent;
  }
  .popup .show {
    visibility: visible;
    -webkit-animation: fadeIn 1s;
    animation: fadeIn 1s;
  }
  .popup .popuptext .btn {
    visibility: hidden;
  }
  .btn5 {
    color: white;
    background-color: #007bff;
    padding: 10px 20px;
    text-decoration: none;
    border-radius: 5px;
    display: inline-block;
    margin-left: 65vh;
    margin-bottom: 15px;
  }
  .btn5:hover {
    background-color: #0056b3;
  }
  @-webkit-keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
`;

const Dash = styled.div`
  body {
    font-family: Arial, sans-serif;
    margin: 0;
    padding: 0;
  }

  .content {
    display: flex;
    margin-top: 20px;
  }

  .main-content {
    display: flex;
    padding: 20px 0px 10px 0px;
    flex-direction: column;
  }
  .table-container {
    margin-top: 20px;
  }
  table {
    width: 100%;
    border-collapse: collapse;
    // border-left: 1px solid rgba(0, 0, 128, 0.2);
    // border-right: 1px solid rgba(0, 0, 128, 0.2);
    border-top: 1px solid rgba(0, 0, 128, 0.2);
    // color: #4a3aff;
    border-radius: 5px;

    border-style: hidden; /* hide standard table (collapsed) border */
    box-shadow: 0 0 0 1px rgba(0, 0, 128, 0.2);
  }
  table tr:last-child td:first-child {
    // border: 2px solid orange;
    // border-bottom-left-radius: 10px;
  }

  table tr:last-child td:last-child {
    // border: 2px solid green;
    // border-bottom-right-radius: 10px;
    // border-radius: 0px 0px 0px 10px;
  }
  th,
  td {
    padding: 10px;
    text-align: left;
    border-bottom: 1px solid rgba(0, 0, 128, 0.2);
  }
  th {
    // background-color: rgba(0, 0, 128, 0.2);
    // font-weight: bold;
    color: rgba(97, 94, 131, 1);
    background: rgba(74, 58, 255, 0.1);
  }
  .record-details {
  }
`;
