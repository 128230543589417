import React from "react";
import { styled } from "styled-components";
import Footer from "../Common/Footer";
import { useLocation, useNavigate } from "react-router-dom";
import { Breadcrumbs, Link, Typography } from "@mui/material";
// import CreditRecommendationReport from "./CreditRecommendationReport";

function TradePartnerRequestIndividual() {
  const { state } = useLocation();
  let navigate = useNavigate();

  return (
    <>
      <div>
        <Finance1>
          <div>
            <div
              role="presentation"
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
                color: "#4A3AFF",

                marginTop: "50px",
              }}
            >
              <Breadcrumbs aria-label="breadcrumb">
                <Link
                  underline="hover"
                  color="rgba(74, 58, 255, 0.5)"
                  onClick={() => {
                    navigate("/creditbazaar/buyer-dashboard");
                  }}
                  className="custom-link"
                >
                  Dashboard
                </Link>
                <Link
                  underline="hover"
                  color="rgba(74, 58, 255, 0.5)"
                  // href="/finance_request_information"
                  onClick={() => {
                    navigate("/creditbazaar/buyer-request-information");
                  }}
                  className="custom-link"
                >
                  Finance Requests
                </Link>
                <Typography color="#4A3AFF">{state?.id ?? ""}</Typography>
              </Breadcrumbs>

              <div className="header">
                <h3 style={{ margin: "0px" }}>Validate</h3>
                <Typography>
                  To participate in the finance request, validate.
                </Typography>
              </div>
            </div>
          </div>
        </Finance1>
        <RequestInformation>
          <div className="main-content">
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <h3 style={{ marginBottom: "5px", color: "#4A3AFF" }}>
                {" "}
                Request Details
              </h3>
            </div>
            <div className="record-details">
              <table>
                <tbody>
                  <tr>
                    <th>Date of Request</th>
                    <td>Nov 19, 2023</td>
                  </tr>
                  <tr>
                    <th>Total Requested amount (INR)</th>
                    <td> 10,00,000 </td>
                  </tr>

                  <tr>
                    <th> Tenure (Days)</th>
                    <td> 90</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div className="main-content">
            <h3 style={{ marginBottom: "5px", color: "#4A3AFF" }}>
              {" "}
              Trade Details
            </h3>
            <div className="record-details">
              <table>
                <tbody>
                  <tr>
                    <th>Date</th>
                    <th>Invoice Number</th>
                    <th>Trade Partner Name</th>
                    <th>Trade Amount (INR)</th>
                    <th>Term (Days)</th>
                  </tr>
                  <tr>
                    <td> Nov 18, 2023 </td>
                    <td> INV569829 </td>
                    <td> Apollo Hospitals </td>
                    <td> 4,50,000 </td>
                    <td> 80 </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>

          <div style={{ marginTop: "50px", textAlign: "center" }}>
            <button
              style={{
                borderRadius: "2px",
                backgroundColor: "#FF5733",
                color: "white",
                border: "none",
                padding: "10px 20px",
                cursor: "pointer",
                borderRadius: "2px",
                height: "fit-content",
                marginRight: "20px",
              }}
              onClick={() =>
                navigate("/creditbazaar/buyer-request-information/request-log")
              }
            >
              Reject
            </button>
            <button
              style={{
                borderRadius: "2px",
                backgroundColor: "#4CAF50",
                color: "white",
                border: "none",
                padding: "10px 20px",
                cursor: "pointer",
                borderRadius: "2px",
                height: "fit-content",
              }}
              onClick={() =>
                navigate("/creditbazaar/buyer-request-information/request-log")
              }
            >
              Accept
            </button>
          </div>
        </RequestInformation>
      </div>

      <div style={{ marginTop: "30vh" }}>
        <Footer />
      </div>
    </>
  );
}

export default TradePartnerRequestIndividual;

const Finance1 = styled.div`
  .header {
    font-size: 18px;
    margin-top: 15px;
  }
  .custom-link:hover {
    color: #4a3aff;
  }
`;

const RequestInformation = styled.div`
  .content {
    display: flex;
    margin-top: 20px;
    padding: 20px;
  }

  .table-container {
    margin-top: 20px;
  }
  table {
    width: 100%;
    border-collapse: collapse;
    border-left: 1px solid rgba(0, 0, 128, 0.2);
    border-right: 1px solid rgba(0, 0, 128, 0.2);
    border-top: 1px solid rgba(0, 0, 128, 0.2);
    // color: #4a3aff;
    border-radius: 2px;

    border-style: hidden; /* hide standard table (collapsed) border */
    box-shadow: 0 0 0 1px rgba(0, 0, 128, 0.2);
  }
  table tr:last-child td:first-child {
    // border: 2px solid orange;
    // border-bottom-left-radius: 10px;
  }

  table tr:last-child td:last-child {
    // border: 2px solid green;
    // border-bottom-right-radius: 10px;
    // border-radius: 0px 0px 0px 10px;
  }
  th,
  td {
    padding: 5px;
    text-align: left;
    border-bottom: 1px solid rgba(0, 0, 128, 0.2);
    border-right: 1px solid rgba(0, 0, 128, 0.2);
  }
  th {
    // background-color: rgba(0, 0, 128, 0.2);
    // font-weight: bold;
    color: rgba(97, 94, 131, 1);
    border-right: 1px solid rgba(0, 0, 128, 0.2);
    // width: 55%;
  }
  table input {
    width: 100%;
    padding: 10px 0px;
  }
  input,
  textarea {
    border: 1px solid rgba(0, 0, 128, 0.2);
    background-color: rgba(229, 234, 252, 0.3);
  }
`;
