import React from "react";
import newLogoExpand from "../../components/images/logoExpand.svg";

const ScheduledMaintenance = () => {
  return (
    <div className="maintenance-container">
      <header>
        {/* Replace 'logo.png' with your actual logo path */}
        <img src={newLogoExpand} alt="Your Application Logo" className="logo" />
      </header>
      <main className="maintenance-content">
        <h1>Scheduled Maintenance Notice</h1>
        <h2>We’re improving your experience!</h2>
        <p>Dear User,</p>
        <p>
          To ensure the highest level of performance and reliability, we have
          scheduled a system maintenance.
        </p>
        <div className="maintenance-time">
          <p>
            <strong>When?</strong>
          </p>
          <p>Start: June 26, 2024, at 8:00 PM IST</p>
          <p>End: June 27, 2024, at 5:00 PM IST</p>
        </div>
        <p>
          During this period, Credit Bazaar Application will not be available.
          We appreciate your patience and understanding and apologize for any
          inconvenience this may cause.
        </p>
        <p>
          <strong>Need Help?</strong>
        </p>
        <p>
          If you have any questions or need further assistance, please don’t
          hesitate to contact our support team at{" "}
          <a href="mailto:operations@profintech.in">operations@profintech.in</a>{" "}
          .
        </p>
        <p>Thank you for your understanding and support.</p>
      </main>
      <footer>
        {/* Add your social media links and other footer content here */}
      </footer>
    </div>
  );
};

export default ScheduledMaintenance;
