import {
  Alert,
  Checkbox,
  Input,
  Snackbar,
  TextField,
  TextareaAutosize,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useAuth } from "../../../context/auth";
import {
  kycContextObjController,
  profileContextObjController,
} from "../../Common/Constants";
import { controllerAPI } from "../../API/FP/Actions";

function ProfileERP({
  profileDetailsApiData,
  firmMemberDetailsList,
  allTypesOfFirmsData,
  isLoading,
  setIsLoading,
}) {
  const ariaLabel = { "aria-label": "description" };
  const label = { inputProps: { "aria-label": "Checkbox demo" } };
  const [checkBoxValue, setCheckboxValue] = useState(false);
  const [erpAccessCheck, setErpAccessCheck] = useState(false);

  const [auth, setAuth] = useAuth();
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [apiResultMessage, setApiResultMessage] = useState("");
  const [alertSeverity, setAlertSeverity] = useState("");

  const authContextObj = {
    transactionDate: new Date().toISOString(),
    userId: auth?.userDetails?.username,
    tenantId: auth?.userDetails?.tenantId,
    cpCode: 1,
    clientCode: 1500,
  };

  const [anchorTraderID, setAnchorTraderID] = useState(
    auth?.userDetails?.personaIds["ANCHOR_TRADER-1"]
  );

  useEffect(() => {
    setErpAccessCheck(profileDetailsApiData?.erpAccess);
  }, [profileDetailsApiData]);

  const saveProfileErpDetail = async (newErpCheck) => {
    const profileObj = {
      id: profileDetailsApiData?.id,
      atUlidId: profileDetailsApiData?.atUlidId,
      orgId: profileDetailsApiData?.orgId,
      // tenantId: auth?.userDetails?.tenantId,
      tenantId: profileDetailsApiData?.tenantId,
      customerName: profileDetailsApiData?.customerName,
      orgName: profileDetailsApiData?.orgName,
      gstId: profileDetailsApiData?.gstId,
      phoneNumber: profileDetailsApiData?.phoneNumber,
      anchorTraderName: profileDetailsApiData?.anchorTraderName,
      location: profileDetailsApiData?.location,
      anchorTraderGST: profileDetailsApiData?.anchorTraderGST,
      anchorTraderSector: profileDetailsApiData?.anchorTraderSector,
      anchorTraderPAN: profileDetailsApiData?.anchorTraderPAN,
      kycCompleted: profileDetailsApiData?.kycCompleted,
      bankDetails: profileDetailsApiData?.bankDetails,
      emailId: profileDetailsApiData?.emailId,
      personalEmailId: profileDetailsApiData?.personalEmailId,
      accountNumber: profileDetailsApiData?.accountNumber,
      ifscCode: profileDetailsApiData?.ifscCode,
      bankName: profileDetailsApiData?.bankName,
      branchName: profileDetailsApiData?.branchName,
      erpAccess: newErpCheck,
      typeOfFirm: profileDetailsApiData?.typeOfFirm,
      panStatus: profileDetailsApiData?.panStatus,
      tosDocument: profileDetailsApiData?.tosDocument,
      acceptTerms: profileDetailsApiData?.acceptTerms,
      acceptDeclaration: profileDetailsApiData?.acceptDeclaration,
      gstRegistrationCertificateUploadStatus:
        profileDetailsApiData?.gstRegistrationCertificateUploadStatus,
      gstRegistrationCertificateVerificationStatus:
        profileDetailsApiData?.gstRegistrationCertificateVerificationStatus,
      udhyamRegistrationcertificateUploadStatus:
        profileDetailsApiData?.udhyamRegistrationcertificateUploadStatus,
      udhyamRegistrationcertificateVerificationStatus:
        profileDetailsApiData?.udhyamRegistrationcertificateVerificationStatus,
      kycDeclaration: profileDetailsApiData?.kycDeclaration,
      organization: profileDetailsApiData?.organization,
    };
    setIsLoading(true);
    try {
      const constructObj = kycContextObjController(
        authContextObj,
        anchorTraderID,
        profileObj,
        firmMemberDetailsList,
        allTypesOfFirmsData
      );
      const saveProfileDetailsResponse = await controllerAPI(
        "post",
        auth?.token,
        constructObj,
        "update-at-profile-details"
      );

      if (saveProfileDetailsResponse.res.status === 200) {
        setOpenSnackbar(true);
        setApiResultMessage("Saved Details Successfully");
        setAlertSeverity("success");
      }
    } catch (err) {
      console.log("Error", err);
      setOpenSnackbar(true);
      setApiResultMessage("Error Occured while saving");
      setAlertSeverity("error");
    } finally {
      setIsLoading(false);
    }
  };
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenSnackbar(false);
  };
  return (
    <div className="body-text">
      <div style={{ display: "flex", flexDirection: "column" }}>
        <div>
          <div
            style={{ color: "#4A3AFF", fontWeight: "bold", marginTop: "20px" }}
          >
            Process Of Installation:
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <div style={{ display: "flex", alignItems: "center" }}>
              <ul>
                <li>The software will be installed in your ERP system.</li>
                <li>
                  There will be plugins, and pre-requisites to be installed.
                </li>
                <li>
                  We shall assist you in the process, which will take 1-2 days,
                  after your consent for access.
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div style={{ marginTop: "2%" }}>
          <div>
            <div
              style={{
                color: "#4A3AFF",
                fontWeight: "bold",
              }}
            >
              Data security and access:
            </div>

            <div
              style={{
                display: "flex",
                flexDirection: "column",
                marginTop: "15px",
              }}
            >
              <div>The data is completely safe at ProFinTech.</div>
              <div style={{ display: "flex", alignItems: "center" }}>
                <ul>
                  <li>Fully secure and full encryption of data.</li>
                  <li>
                    Explicit consent or approval will be taken from the
                    customer.
                  </li>
                  <li>
                    Agreement will be signed with the customer to ensure that
                    there is formal alignment.
                  </li>
                  <li>
                    Personal data, customer pricing, product details will never
                    be shared with any 3rd party.
                  </li>
                  <li>Developed and deployed on AWS cloud.</li>
                </ul>
              </div>
              <div>
                Please provide your consent for accessing your ERP Data.
              </div>
            </div>
          </div>
        </div>

        {/* <h4
          style={{ marginBottom: "5px", marginTop: "60px", color: "#4A3AFF" }}
        >
          {" "}
          <Checkbox
            {...label}
            value={checkBoxValue}
            onChange={() => setCheckboxValue(!checkBoxValue)}
          />
          I hereby declare that the information given above and in the enclosed
          documents is true to the best of my knowledge and belief. Nothing has
          been concealed therein. I acknowledge and accept the terms and
          conditions associated with this agreement.
        </h4> */}
        <div style={{ marginTop: "15px", textAlign: "center" }}>
          <button
            style={{
              borderRadius: "2px",
              backgroundColor: "#FFF",
              color: "#4A3AFF",
              border: "1px solid rgba(0, 0, 128, 0.20)",
              padding: "10px 20px",
              cursor: erpAccessCheck === true ? "pointer" : "not-allowed",
              borderRadius: "2px",
              height: "fit-content",
              boxShadow: "0px 3px 5px 0px rgba(0, 0, 0, 0.10)",
              opacity: erpAccessCheck === true ? "1" : "0.5",
            }}
            onClick={() => {
              setErpAccessCheck(!erpAccessCheck);
              saveProfileErpDetail(!erpAccessCheck);
            }}
            disabled={erpAccessCheck === false ? true : false}
          >
            Reject Access
          </button>
          <button
            style={{
              marginLeft: "10px",
              borderRadius: "2px",
              backgroundColor: "#4A3AFF",
              color: "white",
              border: "none",
              padding: "10px 20px",
              cursor: erpAccessCheck === false ? "pointer" : "not-allowed",
              borderRadius: "2px",
              height: "fit-content",
              boxShadow: "0px 3px 5px 0px rgba(0, 0, 0, 0.10)",
              opacity: erpAccessCheck === false ? "1" : "0.5",
            }}
            onClick={() => {
              setErpAccessCheck(!erpAccessCheck);
              saveProfileErpDetail(!erpAccessCheck);
            }}
            disabled={erpAccessCheck === true ? true : false}
          >
            Allow Access
          </button>
        </div>
      </div>
      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={handleClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        <Alert
          onClose={handleClose}
          severity={alertSeverity}
          sx={{ width: "100%" }}
        >
          {apiResultMessage}
        </Alert>
      </Snackbar>
    </div>
  );
}

export default ProfileERP;
