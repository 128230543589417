import React, { useState } from "react";
import { styled } from "styled-components";
// import Footer from "../Common/Footer";
import { useLocation, useNavigate } from "react-router-dom";
import { Breadcrumbs, Divider, Link, Typography } from "@mui/material";
import {
  Box,
  Checkbox,
  Input,
  TextField,
  TextareaAutosize,
} from "@mui/material";
import { inputLabelClasses } from "@mui/material/InputLabel";
import arrowLeft from "../../../components/images/arrow.svg";
// import TpProfileDetails from "./TpProfileDetails";

function TpProfileIndividual({
  creditScoreReportCheck,
  setCreditScoreReportCheck,
}) {
  const { state } = useLocation();
  let navigate = useNavigate();
  const [details, setDetails] = useState(true);
  const [offer, setOffer] = useState(false);
  const [disbursement, setDisbursement] = useState(false);
  const [repayment, setRepayment] = useState(false);
  const [settlement, setSettlement] = useState(false);
  const [value, setValue] = useState("1");
  const label = { inputProps: { "aria-label": "Checkbox demo" } };
  const [checkBoxValue, setCheckboxValue] = useState(false);
  const [checkBoxValue2, setCheckboxValue2] = useState(false);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <>
      <div>
        <Finance1>
          <div>
            <div
              role="presentation"
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
                color: "#4A3AFF",
                marginTop: "50px",
              }}
            >
              <div className="header">
                <h3 style={{ margin: "0px" }} className="section-heading">
                  Profile
                </h3>
                <Typography style={{ fontSize: "15px" }}>
                  Please fill in the below details
                </Typography>
              </div>
            </div>
          </div>
        </Finance1>
        <RequestInformation>
          <div>
            <Divider
              style={{
                margin: "15px 0px 30px 0px",
                backgroundColor: "#4A3AFF",
              }}
            />

            <div className="allcont">
              <Box sx={{ width: "100%", typography: "body1" }}>
                <div className="body-text">
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <div style={{ display: "flex" }}>
                      <div
                        style={{
                          width: "20%",
                          color: "#000",
                          fontWeight: "bold",
                        }}
                      >
                        Personal Details
                      </div>
                      <div
                        style={{
                          width: "80%",
                          display: "flex",
                          flexDirection: "column",
                        }}
                      >
                        <div style={{ display: "flex" }}>
                          <div
                            style={{ display: "flex", alignItems: "center" }}
                          >
                            <TextField
                              inputProps={{ style: { fontSize: 14 } }}
                              InputLabelProps={{
                                sx: {
                                  color: "#4A3AFF",
                                  fontWeight: "bold",
                                  [`&.${inputLabelClasses.shrink}`]: {
                                    color: "#4A3AFF",
                                  },
                                },
                              }}
                              id="standard-basic"
                              label="Name"
                              defaultValue="Vijay Prathap Reddy"
                              variant="standard"
                            />
                          </div>

                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              marginLeft: "5%",
                            }}
                          >
                            <TextField
                              inputProps={{ style: { fontSize: 14 } }}
                              InputLabelProps={{
                                sx: {
                                  color: "#4A3AFF",
                                  fontWeight: "bold",
                                  [`&.${inputLabelClasses.shrink}`]: {
                                    color: "#4A3AFF",
                                  },
                                },
                              }}
                              id="standard-basic"
                              label="Phone number"
                              defaultValue="8688284439"
                              variant="standard"
                            />
                          </div>
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              marginLeft: "5%",
                            }}
                          >
                            <TextField
                              inputProps={{ style: { fontSize: 14 } }}
                              InputLabelProps={{
                                sx: {
                                  color: "#4A3AFF",
                                  fontWeight: "bold",
                                  [`&.${inputLabelClasses.shrink}`]: {
                                    color: "#4A3AFF",
                                  },
                                },
                              }}
                              id="standard-basic"
                              label="Email address"
                              defaultValue="info@primetextiles.in"
                              variant="standard"
                            />
                          </div>
                        </div>
                      </div>
                    </div>

                    <div style={{ display: "flex", marginTop: "5%" }}>
                      <div
                        style={{
                          width: "20%",
                          color: "#000",
                          fontWeight: "bold",
                        }}
                      >
                        Account details
                      </div>
                      <div
                        style={{
                          width: "80%",
                          display: "flex",
                          flexDirection: "column",
                        }}
                      >
                        <div style={{ display: "flex" }}>
                          <div
                            style={{ display: "flex", alignItems: "center" }}
                          >
                            <TextField
                              inputProps={{ style: { fontSize: 14 } }}
                              InputLabelProps={{
                                sx: {
                                  color: "#4A3AFF",
                                  fontWeight: "bold",
                                  [`&.${inputLabelClasses.shrink}`]: {
                                    color: "#4A3AFF",
                                  },
                                },
                              }}
                              id="standard-basic"
                              label="Account number"
                              defaultValue="10441140232320"
                              variant="standard"
                            />
                          </div>

                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              marginLeft: "5%",
                            }}
                          >
                            <TextField
                              inputProps={{ style: { fontSize: 14 } }}
                              InputLabelProps={{
                                sx: {
                                  color: "#4A3AFF",
                                  fontWeight: "bold",
                                  [`&.${inputLabelClasses.shrink}`]: {
                                    color: "#4A3AFF",
                                  },
                                },
                              }}
                              id="standard-basic"
                              label="IFSC code"
                              defaultValue="HDFC0002096"
                              variant="standard"
                            />
                          </div>
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              marginLeft: "5%",
                            }}
                          >
                            <TextField
                              inputProps={{ style: { fontSize: 14 } }}
                              InputLabelProps={{
                                sx: {
                                  color: "#4A3AFF",
                                  fontWeight: "bold",
                                  [`&.${inputLabelClasses.shrink}`]: {
                                    color: "#4A3AFF",
                                  },
                                },
                              }}
                              id="standard-basic"
                              label="Bank name"
                              defaultValue="HDFC"
                              variant="standard"
                            />
                          </div>
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              marginLeft: "5%",
                            }}
                          >
                            <TextField
                              inputProps={{ style: { fontSize: 14 } }}
                              InputLabelProps={{
                                sx: {
                                  color: "#4A3AFF",
                                  fontWeight: "bold",
                                  [`&.${inputLabelClasses.shrink}`]: {
                                    color: "#4A3AFF",
                                  },
                                },
                              }}
                              id="standard-basic"
                              label="Branch name"
                              defaultValue="Warangal"
                              variant="standard"
                            />
                          </div>
                        </div>
                      </div>
                    </div>

                    <h4 style={{ marginBottom: "0px", marginTop: "60px" }}>
                      {" "}
                      <Checkbox
                        {...label}
                        value={checkBoxValue}
                        onChange={() => setCheckboxValue(!checkBoxValue)}
                      />
                      I have read and accepted the{" "}
                      <span style={{ color: "#4A3AFF", cursor: "pointer" }}>
                        Terms and Conditions
                      </span>
                      .
                    </h4>
                    <h4 style={{ marginBottom: "5px", marginTop: "0px" }}>
                      {" "}
                      <Checkbox
                        {...label}
                        value={checkBoxValue2}
                        onChange={() => setCheckboxValue2(!checkBoxValue2)}
                      />
                      I hereby declare that the information given above is true
                      to the best of my knowledge and belief.
                    </h4>
                    <div style={{ marginTop: "15px", textAlign: "center" }}>
                      <button
                        style={{
                          borderRadius: "2px",
                          backgroundColor: "#4A3AFF",
                          color: "white",
                          border: "none",
                          padding: "10px 20px",
                          cursor: "pointer",
                          borderRadius: "2px",
                          height: "fit-content",
                          boxShadow: "0px 3px 5px 0px rgba(0, 0, 0, 0.10)",
                          opacity:
                            checkBoxValue && checkBoxValue2 ? "1" : "0.5",
                        }}
                        //   onClick={() => navigate("/request_log")}
                      >
                        Save Details
                      </button>
                    </div>
                  </div>
                </div>
              </Box>
            </div>
          </div>
        </RequestInformation>
      </div>
      {/* <div style={{ marginTop: "30vh" }}>
        <Footer />
      </div> */}
    </>
  );
}

export default TpProfileIndividual;

const Finance1 = styled.div`
  .header {
    font-size: 18px;
    margin-top: 15px;
    // margin-left: 10px;
    // display: flex;
  }
  .report-button {
    border-radius: 2px;
    background-color: white;
    color: #4a3aff;
    border: none;
    padding: 10px 20px;
    cursor: pointer;
    height: fit-content;
    margin-right: 25px;
    border: 1px solid rgba(0, 0, 128, 0.2);
    font-size: 11px;
  }
`;

const RequestInformation = styled.div`
  .content {
    display: flex;
    margin-top: 20px;
    padding: 20px;
  }

  .table-container {
    margin-top: 20px;
  }
  table {
    width: 100%;
    border-collapse: collapse;
    border-left: 1px solid rgba(0, 0, 128, 0.2);
    border-right: 1px solid rgba(0, 0, 128, 0.2);
    border-top: 1px solid rgba(0, 0, 128, 0.2);
    // color: #4a3aff;
    border-radius: 5px;

    border-style: hidden; /* hide standard table (collapsed) border */
    box-shadow: 0 0 0 1px rgba(0, 0, 128, 0.2);
  }
  table tr:last-child td:first-child {
    // border: 2px solid orange;
    // border-bottom-left-radius: 10px;
  }

  table tr:last-child td:last-child {
    // border: 2px solid green;
    // border-bottom-right-radius: 10px;
    // border-radius: 0px 0px 0px 10px;
  }
  th,
  td {
    padding: 10px;
    text-align: left;
    border-bottom: 1px solid rgba(0, 0, 128, 0.2);
    border-right: 1px solid rgba(0, 0, 128, 0.2);
  }
  th {
    // background-color: rgba(0, 0, 128, 0.2);
    // font-weight: bold;
    color: rgba(97, 94, 131, 1);
    // width: 55%;
  }
  .main-content {
    margin-top: 30px;
  }
`;
