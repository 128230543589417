export const CreateRequestsInvoicesMockData = [
  {
    invoiceNumber: "PT-2780-2023-24",
    date: "Dec 16, 2023",
    tradePartnerName: "Sankalpa Hospital",
    amount: 38350,
    term: 50,
    tradePartnerReview: "Not reviewed",
  },
  {
    invoiceNumber: "PT-2803-2023-24",
    date: "Dec 20, 2023",
    tradePartnerName: "Eshwar Surgicals",
    amount: 47600,
    term: 60,
    tradePartnerReview: "Approved",
  },
  {
    invoiceNumber: "PT-2811-2023-24",
    date: "Dec 21, 2023",
    tradePartnerName: "Renee Hospital",
    amount: 45600,
    term: 50,
    tradePartnerReview: "Not reviewed",
  },
  {
    invoiceNumber: "PT-2824-2023-24",
    date: "Dec 21, 2023",
    tradePartnerName: "MIMS Hospital",
    amount: 55400,
    term: 60,
    tradePartnerReview: "Not reviewed",
  },
  {
    invoiceNumber: "PT-2832-2023-24",
    date: "Dec 22, 2023",
    tradePartnerName: "Sangamitra Hospital",
    amount: 101775,
    term: 60,
    tradePartnerReview: "Approved",
  },
  {
    invoiceNumber: "PT-2827-2023-24",
    date: "Dec 22, 2023",
    tradePartnerName: "Techline Clothing",
    amount: 18600,
    term: 50,
    tradePartnerReview: "Not reviewed",
  },
  {
    invoiceNumber: "PT-2863-2023-24",
    date: "Dec 22, 2023",
    tradePartnerName: "Spandana Hospital",
    amount: 57750,
    term: 60,
    tradePartnerReview: "Approved",
  },
  // {
  //   invoiceNumber: "PT-2871-2023-24",
  //   date: "Dec 25, 2023",
  //   tradePartnerName: "Andhra Hospitals",
  //   amount: 7000,
  //   term: 70,
  //   tradePartnerReview: "Not reviewed",
  // },
  // {
  //   invoiceNumber: "PT-2889-2023-24",
  //   date: "Dec 27, 2023",
  //   tradePartnerName: "Aware Global Hospital",
  //   amount: 10850,
  //   term: 60,
  //   tradePartnerReview: "Not reviewed",
  // },
  // {
  //   invoiceNumber: "PT-2713-2023-24",
  //   date: "Dec 17, 2023",
  //   tradePartnerName: "Tulasi Hospitals",
  //   amount: 8000,
  //   term: 60,
  //   tradePartnerReview: "Not reviewed",
  // },
];

export const CreateRequestsInvoicesSelectedMockData = [
  {
    invoiceNumber: "PT-2780-2023-24",
    date: "Dec 16, 2023",
    tradePartnerName: "Sankalpa Hospital",
    amount: 38350,
    term: 50,
    tradePartnerReview: "Not reviewed",
  },
  {
    invoiceNumber: "PT-2803-2023-24",
    date: "Dec 20, 2023",
    tradePartnerName: "Eshwar Surgicals",
    amount: 47600,
    term: 60,
    tradePartnerReview: "Approved",
  },
  {
    invoiceNumber: "PT-2811-2023-24",
    date: "Dec 21, 2023",
    tradePartnerName: "Renee Hospital",
    amount: 45600,
    term: 50,
    tradePartnerReview: "Not reviewed",
  },
  {
    invoiceNumber: "PT-2824-2023-24",
    date: "Dec 21, 2023",
    tradePartnerName: "MIMS Hospital",
    amount: 55400,
    term: 60,
    tradePartnerReview: "Not reviewed",
  },
  {
    invoiceNumber: "PT-2832-2023-24",
    date: "Dec 22, 2023",
    tradePartnerName: "Sangamitra Hospital",
    amount: 101775,
    term: 60,
    tradePartnerReview: "Approved",
  },
];

export const PlacedRequestsMockData = [
  {
    requestId: "REQ-2023-123456",
    date: "Jan 01, 2024",
    tradePartnerName: "Multiple",
    tradeValue: "2,88,725",
    status: "AWAITING_OFFER",
  },
  {
    requestId: "REQ-2023-147894",
    date: "Dec 20, 2023",
    tradePartnerName: "Renova Hospitals",
    tradeValue: "6,50,000",
    status: "AWAITING_DISBURSEMENT",
  },
  {
    requestId: "REQ-2023-852369",
    date: "Dec 21, 2023",
    tradePartnerName: "Acme.co",
    tradeValue: "7,00,000",
    status: "PENDING_REPAYMENT",
  },
];

export const PlacedRequestsOffersMockData = [
  {
    financePartnerName: "Paisabuddy",
    interest: "18",
    margin: "20",
    term: "60",
  },
  {
    financePartnerName: "HDFC",
    interest: "15",
    margin: "20",
    term: "60",
  },
  {
    financePartnerName: "IDFC",
    interest: "20",
    margin: "20",
    term: "60",
  },
  {
    financePartnerName: "ICICI",
    interest: "15",
    margin: "20",
    term: "60",
  },
];

export const PlacedRequestInvoicesData = [
  {
    id: 1547,
    tradeId: "109",
    tradeRefNumber: "TR109",
    sellerGstId: "36AAIFP3688H1ZS",
    buyerGstId: "37ADCFS3132Q1ZP",
    tradeAmount: "38350",
    invoiceDate: "2023-12-16",
    invoiceNumber: "PT-2780-2023-24",
    tradeDocType: "Invoice",
    tradeDocSource: "Tally",
    tradeDocCredibility: "High",
    tradeMilestoneStatus: "Unknown",
    tradeAdvancePayment: "No",
    anchorTraderName: "Prime Textiles",
    tradePartnerName: "Sankalpa Hospital",
    invoiceTerm: 70,
    acceptanceFromTradePartner: "Not Reviewed",
    reasonForFinance: "Buying Raw Materials",
    tradePartnerSector: "Hospitals",
    tradePartnerLocation: "Tirupati",
    tradePartnerGstComplianceScore: "70",
    financerequest: {
      id: 1505,
      requestId: "01HK84A2X99GNY0BRWXXJQS00M",
      financeRequestRefNo: null,
      tradeChannelId: null,
      requestAmount: null,
      requestDate: null,
      currency: null,
      requestStatus: null,
      dueDate: null,
      anchortrader: null,
    },
    tradepartner: {
      id: 1507,
      orgId: null,
      tenantId: null,
      tpId: "TP886",
      customerName: null,
      orgName: null,
      gstId: null,
      phoneNumber: null,
      tradePartnerName: null,
      location: null,
      tradePartnerGST: null,
      tradePartnerSector: null,
      acceptanceFromTradePartner: null,
      organization: null,
    },
    anchortrader: {
      id: 1500,
      tenantId: null,
      atId: "AT5744",
      orgId: null,
      customerName: null,
      orgName: null,
      gstId: null,
      phoneNumber: null,
      anchorTraderName: null,
      location: null,
      anchorTraderGST: null,
      anchorTraderSector: null,
      anchorTraderPAN: null,
      kycCompleted: null,
      bankDetails: null,
    },
  },
  {
    id: 1546,
    tradeId: "110",
    tradeRefNumber: "TR110",
    sellerGstId: "36AAIFP3688H1ZS",
    buyerGstId: "36AGHPM2768A1ZI",
    tradeAmount: "47600",
    invoiceDate: "2024-01-20",
    invoiceNumber: "PT-2803-2023-24",
    tradeDocType: "Invoice",
    tradeDocSource: "Tally",
    tradeDocCredibility: "High",
    tradeMilestoneStatus: "Unknown",
    tradeAdvancePayment: "No",
    anchorTraderName: "Prime Textiles",
    tradePartnerName: "Eshwar Surgicals",
    invoiceTerm: 60,
    acceptanceFromTradePartner: "Approved",
    reasonForFinance: "Buying Raw Materials",
    tradePartnerSector: "Hospitals",
    tradePartnerLocation: "Hanamkonda",
    tradePartnerGstComplianceScore: "80",
    financerequest: {
      id: 1505,
      requestId: "01HK84A2X99GNY0BRWXXJQS00M",
      financeRequestRefNo: null,
      tradeChannelId: null,
      requestAmount: null,
      requestDate: null,
      currency: null,
      requestStatus: null,
      dueDate: null,
      anchortrader: null,
    },
    tradepartner: {
      id: 1508,
      orgId: null,
      tenantId: null,
      tpId: "TP887",
      customerName: null,
      orgName: null,
      gstId: null,
      phoneNumber: null,
      tradePartnerName: null,
      location: null,
      tradePartnerGST: null,
      tradePartnerSector: null,
      acceptanceFromTradePartner: null,
      organization: null,
    },
    anchortrader: {
      id: 1500,
      tenantId: null,
      atId: "AT5744",
      orgId: null,
      customerName: null,
      orgName: null,
      gstId: null,
      phoneNumber: null,
      anchorTraderName: null,
      location: null,
      anchorTraderGST: null,
      anchorTraderSector: null,
      anchorTraderPAN: null,
      kycCompleted: null,
      bankDetails: null,
    },
  },
  {
    id: 1544,
    tradeId: "111",
    tradeRefNumber: "TR111",
    sellerGstId: "36AAIFP3688H1ZS",
    buyerGstId: "36AAMFR4245E1Z5",
    tradeAmount: "45,600",
    invoiceDate: "2023-12-21",
    invoiceNumber: "PT-2811-2023-24",
    tradeDocType: "Invoice",
    tradeDocSource: "Tally",
    tradeDocCredibility: "High",
    tradeMilestoneStatus: "Unknown",
    tradeAdvancePayment: "No",
    anchorTraderName: "Prime Textiles",
    tradePartnerName: "Renee Hospital",
    invoiceTerm: 50,
    acceptanceFromTradePartner: "Not Reviewed",
    reasonForFinance: "Buying Raw Materials",
    tradePartnerSector: "Hospitals",
    tradePartnerLocation: "Karimnagar",
    tradePartnerGstComplianceScore: "70",
    financerequest: {
      id: 1505,
      requestId: "01HK84A2X99GNY0BRWXXJQS00M",
      financeRequestRefNo: null,
      tradeChannelId: null,
      requestAmount: null,
      requestDate: null,
      currency: null,
      requestStatus: null,
      dueDate: null,
      anchortrader: null,
    },
    tradepartner: {
      id: 1509,
      orgId: null,
      tenantId: null,
      tpId: "TP889",
      customerName: null,
      orgName: null,
      gstId: null,
      phoneNumber: null,
      tradePartnerName: null,
      location: null,
      tradePartnerGST: null,
      tradePartnerSector: null,
      acceptanceFromTradePartner: null,
      organization: null,
    },
    anchortrader: {
      id: 1500,
      tenantId: null,
      atId: "AT5744",
      orgId: null,
      customerName: null,
      orgName: null,
      gstId: null,
      phoneNumber: null,
      anchorTraderName: null,
      location: null,
      anchorTraderGST: null,
      anchorTraderSector: null,
      anchorTraderPAN: null,
      kycCompleted: null,
      bankDetails: null,
    },
  },
  {
    id: 1543,
    tradeId: "112",
    tradeRefNumber: "TR112",
    sellerGstId: "36AAIFP3688H1ZS",
    buyerGstId: "36ABUFM1806D1Z9",
    tradeAmount: "55,400",
    invoiceDate: "2023-12-21",
    invoiceNumber: "PT-2824-2023-24",
    tradeDocType: "Invoice",
    tradeDocSource: "Tally",
    tradeDocCredibility: "High",
    tradeMilestoneStatus: "Unknown",
    tradeAdvancePayment: "No",
    anchorTraderName: "Prime Textiles",
    tradePartnerName: "MIMS Hospital",
    invoiceTerm: 60,
    acceptanceFromTradePartner: "Not Reviewed",
    reasonForFinance: "Buying Raw Materials",
    tradePartnerSector: "Hospitals",
    tradePartnerLocation: "Bhadrachalam",
    tradePartnerGstComplianceScore: "80",
    financerequest: {
      id: 1505,
      requestId: "01HK84A2X99GNY0BRWXXJQS00M",
      financeRequestRefNo: null,
      tradeChannelId: null,
      requestAmount: null,
      requestDate: null,
      currency: null,
      requestStatus: null,
      dueDate: null,
      anchortrader: null,
    },
    tradepartner: {
      id: 1510,
      orgId: null,
      tenantId: null,
      tpId: "TP888",
      customerName: null,
      orgName: null,
      gstId: null,
      phoneNumber: null,
      tradePartnerName: null,
      location: null,
      tradePartnerGST: null,
      tradePartnerSector: null,
      acceptanceFromTradePartner: null,
      organization: null,
    },
    anchortrader: {
      id: 1500,
      tenantId: null,
      atId: "AT5744",
      orgId: null,
      customerName: null,
      orgName: null,
      gstId: null,
      phoneNumber: null,
      anchorTraderName: null,
      location: null,
      anchorTraderGST: null,
      anchorTraderSector: null,
      anchorTraderPAN: null,
      kycCompleted: null,
      bankDetails: null,
    },
  },
  {
    id: 1542,
    tradeId: "113",
    tradeRefNumber: "TR113",
    sellerGstId: "36AAIFP3688H1ZS",
    buyerGstId: "37AAMCS2557B1ZB",
    tradeAmount: "101,775",
    invoiceDate: "2023-12-22",
    invoiceNumber: "PT-2832-2023-24",
    tradeDocType: "Invoice",
    tradeDocSource: "Tally",
    tradeDocCredibility: "High",
    tradeMilestoneStatus: "Unknown",
    tradeAdvancePayment: "No",
    anchorTraderName: "Prime Textiles",
    tradePartnerName: "Sangamitra Hospital",
    invoiceTerm: 70,
    acceptanceFromTradePartner: "Approved",
    reasonForFinance: "Buying Raw Materials",
    tradePartnerSector: "Hospitals",
    tradePartnerLocation: "Ongole",
    tradePartnerGstComplianceScore: "80",
    financerequest: {
      id: 1505,
      requestId: "01HK84A2X99GNY0BRWXXJQS00M",
      financeRequestRefNo: null,
      tradeChannelId: null,
      requestAmount: null,
      requestDate: null,
      currency: null,
      requestStatus: null,
      dueDate: null,
      anchortrader: null,
    },
    tradepartner: {
      id: 1511,
      orgId: null,
      tenantId: null,
      tpId: "TP890",
      customerName: null,
      orgName: null,
      gstId: null,
      phoneNumber: null,
      tradePartnerName: null,
      location: null,
      tradePartnerGST: null,
      tradePartnerSector: null,
      acceptanceFromTradePartner: null,
      organization: null,
    },
    anchortrader: {
      id: 1500,
      tenantId: null,
      atId: "AT5744",
      orgId: null,
      customerName: null,
      orgName: null,
      gstId: null,
      phoneNumber: null,
      anchorTraderName: null,
      location: null,
      anchorTraderGST: null,
      anchorTraderSector: null,
      anchorTraderPAN: null,
      kycCompleted: null,
      bankDetails: null,
    },
  },
];

export const PlacedRequestDisbursementMockData = {
  id: 1503,
  dbmtId: "01HK85D1WYMHNHANACD2Z5GD82",
  disbursementRefNo: "DBCR-IKF-1503",
  acceptedOfferId: 1502,
  offerId: 1503,
  offerAcceptedDate: "2024-01-05",
  dbmtRequestId: "4545",
  dbmtReqAmount: 224146,
  currency: "4",
  dbmtRequestDate: "2024-01-05",
  dbmtStatus: "Awaiting Disbursement",
  offerStatus: "Awaiting Disbursement",
  ftTrnxDetailsId: "101",
  collectionTrnxDetailsId: "1003",
  docId: "101",
  dbmtDateTime: "01/10/2023",
  dbmtAmount: 111111111,
  financeRequestId: "01HK84A2X99GNY0BRWXXJQS00M",
  amountToBeDisbursed: "224146",
  destinationAccountName: "IDFC Escrow account",
  destinationAccountNumber: "PBD110000000001",
  ifscCode: "IDFB0080202",
  status: "Awaiting Disbursement",
  actionByDate: "2024-01-05",
  financerequest: {
    id: 1505,
    requestId: "01HK84A2X99GNY0BRWXXJQS00M",
    financeRequestRefNo: null,
    tradeChannelId: null,
    requestAmount: null,
    requestDate: null,
    currency: null,
    requestStatus: null,
    dueDate: null,
    anchortrader: null,
  },
  financepartner: {
    id: 1500,
    tenantId: null,
    fpId: "111",
    orgId: null,
    customerName: null,
    orgName: null,
    gstId: null,
    phoneNumber: null,
    organization: null,
  },
};
