export const requestStates = (value) => {
  if (value.toString() === "OFPD") {
    return "Awaiting Acceptance";
  } else if (value.toString() === "DBCD") {
    return "Awaiting Repayment";
  } else if (value.toString() === "AODCD") {
    return "Awaiting Repayment";
  } else if (value.toString() === "ACCEPTED") {
    return "Accepted";
  } else if (value.toString() === "POAD") {
    return "Awaiting Disbursement";
  } else if (value.toString() === "OFAD") {
    return "Awaiting Disbursement";
  } else if (value.toString() === "CANCELLED") {
    return "Cancelled";
  } else if (value.toString() === "COMPLETED") {
    return "Completed";
  } else if (value.toString() === "FRCD") {
    return "Request Closed";
  } else if (value.toString() === "FRDL") {
    return "Request Declined";
  } else if (value.toString() === "RODL") {
    return "Request Offer Declined";
  } else if (value.toString() === "REQUEST_CREATED") {
    return "Request Created";
  } else if (value.toString() === "DECLINED") {
    return "Declined";
  } else if (value.toString() === "RPDF") {
    return "Default";
  } else if (value.toString() === "AORDF ") {
    return "Default";
  } else if (value.toString() === "DISBURSED") {
    return "Disbursed";
  } else if (value.toString() === "DISBURSEMENT") {
    return "Disbursement";
  } else if (value.toString() === "DISBURSEMENT_DONE") {
    return "Disbursement Done";
  } else if (value.toString() === "DBCR") {
    return "Disbursement Created";
  } else if (value.toString() === "DBIN") {
    return "Disbursement Initiated";
  } else if (value.toString() === "DBCD") {
    return "Disbursement Completed";
  } else if (value.toString() === "POAD ") {
    return "Disbursement Pending";
  } else if (value.toString() === "POAD ") {
    return "Disbursement Pending";
  } else if (value.toString() === "POEX") {
    return "Expired";
  } else if (value.toString() === "FAILED") {
    return "Failed";
  } else if (value.toString() === "FRCD") {
    return "Finance Request Closed";
  } else if (value.toString() === "FULLY_PAID") {
    return "Fully Paid";
  } else if (value.toString() === "IN_PROCESS") {
    return "In Process";
  } else if (value.toString() === "INPROCESS") {
    return "In Process";
  } else if (value.toString() === "INITIATED") {
    return "Initiated";
  } else if (value.toString() === "OFFER_ACCEPTED") {
    return "Offer Accepted";
  } else if (value.toString() === "FREX") {
    return "Finance Request Expired";
  } else if (value.toString() === "OFPD") {
    return "Offer Placed";
  } else if (value.toString() === "ROCR") {
    return "Open Offer";
  } else if (value.toString() === "OPEN_REQUEST") {
    return "Open Request";
  } else if (value.toString() === "OVERDUE") {
    return "Overdue";
  } else if (value.toString() === "PARTIALLY_PAID") {
    return "Partially Paid";
  } else if (value.toString() === "PDCD") {
    return "Placed Offer Closed";
  } else if (value.toString() === "PLACED_OFFER_DECLINED") {
    return "Placed Offer Declined";
  } else if (value.toString() === "FRARJ") {
    return "Request Rejected";
  } else if (value.toString() === "ROEX") {
    return "Request Expired";
  } else if (value.toString() === "FRCR") {
    return "Awaiting Offer";
  } else if (value.toString() === "DBCD ") {
    return "Pending Repayment";
  } else if (value.toString() === "DBCD ") {
    return "Repayment Pending";
  } else if (value.toString() === "REQUEST_ACCEPTED") {
    return "Request Accepted";
  } else if (value.toString() === "REQUEST_INPROCESS") {
    return "Request Inprocess";
  } else if (value.toString() === "AORIN ") {
    return "Repayment Done";
  } else if (value.toString() === "RPCD") {
    return "Repayment Done";
  } else if (value.toString() === "ROCD") {
    return "Request Offer Closed";
  } else if (value.toString() === "FRPR") {
    return "Request Processed";
  } else if (value.toString() === "REQUEST_PROCESSING") {
    return "Request Processing";
  } else if (value.toString() === "PDCD") {
    return "Placed Offer Closed";
  } else if (value.toString() === "REQUEST_RAISED") {
    return "Request Raised";
  } else if (value.toString() === "PROCESSING") {
    return "Processing";
  } else if (value.toString() === "FRARJ") {
    return "Rejected";
  } else if (value.toString() === "FRPRJ") {
    return "Rejected by profintech";
  } else if (value.toString() === "RPCR") {
    return "Repayment Created";
  } else if (value.toString() === "RPIN") {
    return "Repayment Initiated";
  } else if (value.toString() === "SCHEDULED") {
    return "Scheduled";
  } else if (value.toString() === "FRUR") {
    return "Finance Request Under Review";
  } else if (value.toString() === "STCR") {
    return "Settlement Created";
  } else if (value.toString() === "STCD") {
    return "Settlement Completed";
  } else if (value.toString() === "PSCR") {
    return "Participant Settlement Created";
  } else if (value.toString() === "PSCD") {
    return "Participant Settlement Completed";
  } else if (value.toString() === "RPOD ") {
    return "Repayment Overdue";
  } else if (value.toString() === "AOROD ") {
    return "Repayment Overdue";
  } else if (value.toString() === "PENDING_DISBURSEMENT") {
    return "Pending Disbursement";
  }
};

export const newstates = (value) => {
  if (value.toString() === "NOT_REVIEWED") {
    return "Not Reviewed";
  } else if (value.toString() === "APPROVED") {
    return "Approved";
  } else if (value.toString() === "FRARJ") {
    return "Rejected";
  }
};
export const dateFormat = (date, days) => {
  date.setDate(date.getDate() + days);
  let month = date.toLocaleString("default", { month: "short" }); //months from 1-12
  let day = date.getUTCDate();
  let year = date.getUTCFullYear();
  let hour = date.getHours();
  let minutes = date.getMinutes();
  return month + " " + day + " " + year + ", " + hour + ":" + minutes;
};

const actionDescription = (actionCode) => {
  if (actionCode === "fetch") {
    return "Fetching ....";
  } else if (actionCode === "new") {
    return "Creating a new Placed Offer";
  }
};

export const contextObj = (authContextObj, id) => {
  return {
    context: authContextObj,
    message: {
      id: id,
    },
  };
};
export const contextObjforcrepostapicall = (
  authContextObj,
  id,
  credata,
  individualAssessmentList,
  selectedRecords,
  financeRequest
) => {
  return {
    context: authContextObj,
    anchorTraderUlidId: id,
    cbcreProcess: credata,
    individualAssessmentList: individualAssessmentList,
    tradeMasterList: selectedRecords,
    financeRequest: financeRequest,
  };
};

export const contextObjforcreapicall = (authContextObj, id, amount) => {
  return {
    context: authContextObj,
    anchorTraderUlidId: id,
    amount: amount,
  };
};

export const contextObjforlimitsapicall = (authContextObj, id) => {
  return {
    context: authContextObj,
    anchorTraderUlidId: id,
  };
};

export const contextforfilterapicall = (authContextObj, id) => {
  return {
    context: authContextObj,
    message: {
      id: id,
    },
  };
};
export const contextObjforapicall = (authContextObj, id) => {
  return {
    context: authContextObj,
    message: {
      id: id,
    },
  };
};
export const objectforOPSsetdisb = (id) => {
  return {
    acceptedOfferId: id,
  };
};
export const objectforOPSReconciledisb = (id) => {
  return {
    escrowTransactionRecordId: id,
  };
};

export const objectforOPSPrepareDisb = (id) => {
  return {
    financeRequestId: id,
  };
};
export const objectforOPSForReconcileRep = (id) => {
  return {
    escrowTransactionRecordId: id,
  };
};
export const objectforRealfundsTransfer = (path) => {
  return {
    fundTransferS3Path: path,
  };
};
export const objectforsetrepaymentdetails = (id) => {
  return {
    disbursementId: id,
  };
};
export const objecttocompleteDisbursment = (
  disbursement,
  placedOffer,
  acceptedOffer,
  disbursementDate
) => {
  return {
    disbursement: disbursement,
    placedOffer: placedOffer,
    acceptedOffer: acceptedOffer,
    disbursementDate: disbursementDate,
  };
};

export const objectforsetSettlement = (id) => {
  return {
    repaymentId: id,
  };
};

export const completeParticipantSettlement = (
  participantSettlement,
  fundsTransferTransactionDetails
) => {
  return {
    participantSettlement: participantSettlement,
    fundsTransferTransactionDetails: fundsTransferTransactionDetails,
  };
};
export const PreparetriggerSettlementFundsTransfer = (fundTransferS3Path) => {
  return {
    fundTransferS3Path: fundTransferS3Path,
  };
};

export const CompleteSettlement = (
  financeRequest,
  requestOffer,
  placedOffer,
  acceptedOffer,
  repayment,
  settlement
) => {
  return {
    financeRequest: financeRequest,
    requestOffer: requestOffer,
    placedOffer: placedOffer,
    acceptedOffer: acceptedOffer,
    repayment: repayment,
    settlement: settlement,
  };
};
export const PreparetriggerSettlementBeneValidation = (
  participantSettlement,
  beneValidationS3Path
) => {
  return {
    participantSettlement: participantSettlement,
    beneValidationS3Path: beneValidationS3Path,
  };
};
export const PrepareParticipantSettlement = (
  financeRequestId,
  participantSettlementId
) => {
  return {
    financeRequestId: financeRequestId,
    participantSettlementId: participantSettlementId,
  };
};
export const objectforBeneval = (beneValidationS3Path, disbursement) => {
  return {
    disbursement: disbursement,
    beneValidationS3Path: beneValidationS3Path,
  };
};

export const contextforrejectingOffer = (authContextObj, id, finalReason) => {
  return {
    context: authContextObj,
    message: {
      requestOfferId: id,
      rejectedOfferReason: finalReason,
    },
  };
};
export const contextObjForPlacingOffer = (
  authContextObj,
  requestOfferObj,
  placedOfferObj
) => {
  return {
    context: authContextObj,
    message: {
      id: "",
      msgDetails: {
        requestOffer: requestOfferObj,
        placedOffer: placedOfferObj,
      },
    },
  };
};

export const contextObjController = (authContextObj, anchorTraderID) => {
  return {
    context: authContextObj,
    message: {
      id: anchorTraderID,
    },
  };
};

export const profileContextObjController = (
  authContextObj,
  anchorTraderID,
  anchorTraderObj = {}
) => {
  return {
    context: authContextObj,
    message: {
      id: anchorTraderID,
      msgDetails: {
        anchorTrader: anchorTraderObj,
        firmMemberDetailsList: [],
      },
      // ...(searchText ? {query: `${searchText}${!isNaN(searchText) ? `  OR exportID:${searchText}` : ''}`} : {}),
    },
  };
};
export const signUp = (formData) => {
  return {
    firmName: formData.firmName,
    ownerName: formData?.ownerName,
    email: formData?.emailAddress,
    gstNumber: formData?.gstNumber,
    typeOfFirm: formData?.typeOfFirm,
    phoneNumber: formData?.phoneNumber,
    address: formData?.address,
    personaType: formData?.sellerBuyer,
    controllerSignUp: true,
  };
};
export const multigstController = (
  authContextObj,
  anchorTraderID,
  gstcompanyname,
  gstNumber
) => {
  return {
    context: authContextObj,
    message: {
      id: anchorTraderID,
      companyName: gstcompanyname,
      companyGstId: gstNumber,
    },
  };
};

export const kycMultiGstIdController = (
  authContextObj,
  gstId,
  anchorTraderID
) => {
  return {
    context: authContextObj,
    message: {
      id: anchorTraderID,
      gstinId: gstId,
    },
  };
};

export const objectforGetERP = (authContextObj, gstinId, isErpAccess) => {
  return {
    context: authContextObj,
    message: {
      gstinId: gstinId,
      isErpAccess: isErpAccess,
    },
  };
};
export const kycContextObjController = (
  authContextObj,
  anchorTraderID,
  anchorTraderObj = {},
  firmMemberDetailsList,
  kycMaster = {},
  gstinId
) => {
  return {
    context: authContextObj,
    message: {
      id: anchorTraderID,
      gstinId: gstinId,
      msgDetails: {
        anchorTrader: anchorTraderObj,
        kycMaster: kycMaster,
        firmMemberDetailsList: firmMemberDetailsList,
      },
      // ...(searchText ? {query: `${searchText}${!isNaN(searchText) ? `  OR exportID:${searchText}` : ''}`} : {}),
    },
  };
};

export const panValidateContextObjController = (
  authContextObj,
  anchorTraderID,
  gstID,
  panRequest
) => {
  return {
    context: authContextObj,
    message: {
      id: anchorTraderID,
      gstinId: gstID,
      msgDetails: {
        panRequest: panRequest,
      },
    },
  };
};

export const generateOTPContextObjController = (
  authContextObj,
  id,
  generateOTPRequest
) => {
  return {
    context: authContextObj,
    message: {
      id: "",
      msgDetails: {
        generateOTPRequest: generateOTPRequest,
      },
    },
  };
};

export const verifyOTPContextObjController = (
  authContextObj,
  id,
  gstID,
  validateOTPRequest
) => {
  return {
    context: authContextObj,
    message: {
      id: id,
      gstinId: gstID,
      msgDetails: {
        validateOTPRequest: validateOTPRequest,
      },
    },
  };
};

export const presignedContextObjController = (
  authContextObj,
  id,
  documentCode
) => {
  return {
    context: authContextObj,
    message: {
      id: id,
      msgDetails: {
        documentCode: documentCode,
      },
    },
  };
};

export const gstCompanyDetailsContextObjController = (authContextObj, id) => {
  return {
    context: authContextObj,
    message: {
      id: id,
    },
  };
};

export const gstCompanyGenerateOTPContextObjController = (
  authContextObj,
  gstNumber,
  gstUsername
) => {
  return {
    context: authContextObj,
    message: {
      msgDetails: {
        generateGSTOTPRequest: {
          gstin_no: gstNumber,
          gstin_username: gstUsername,
        },
      },
    },
  };
};

export const gstCompanyVerifyOTPContextObjController = (
  authContextObj,
  gstNumber,
  gstUsername,
  gstOTP,
  accessToken
) => {
  return {
    context: authContextObj,
    message: {
      msgDetails: {
        verifyGSTOTPRequest: {
          gstin_no: gstNumber,
          gstin_username: gstUsername,
          gstin_otp: gstOTP,
          access_token: accessToken,
        },
      },
    },
  };
};

export const onAcceptedOfferContextObjController = (
  authContextObj,
  placedOfferUlidId,
  financeRequestUlidId,
  msgDetails
) => {
  return {
    context: authContextObj,
    message: {
      placedOfferUlidId: placedOfferUlidId,
      financeRequestUlidId: financeRequestUlidId,
      msgDetails: msgDetails,
    },
  };
};
