import React from "react";
import { styled } from "styled-components";

import { FaYoutube, FaFacebook, FaInstagram, FaTwitter } from "react-icons/fa";

function Footer() {
  return (
    <Footer1>
      <div className="footer-content">
        <p>
          © Copyright 2024.<strong>ProFinTech</strong>. All Rights Reserved |
          Credit Bazaar
        </p>
      </div>
    </Footer1>
  );
}

export default Footer;

const Footer1 = styled.div`
  position: absolute;
  // left: 0;
  bottom: 0;
  width: -webkit-fill-available;
  background-color: white;
  color: #012970;
  text-align: center;
  // border-top: 1px solid #cddfff;

  footer {
    background-color: black;
    text-align: center;
  }

  .footer-content {
    display: flex;

    justify-content: center;
    align-items: center;
  }

  .footer-content p {
    margin-bottom: 5px;
  }

  // .footer-links {
  //   list-style-type: none;
  //   padding: 0;
  // }

  // .footer-links li {
  //   display: inline-block;
  //   margin-right: 10px;
  // }

  // .footer-links li a {
  //   color: #555;
  //   text-decoration: none;
  // }

  // .footer-links li a:hover {
  //   text-decoration: underline;
  // }
`;
