import React, { useEffect, useRef, useState } from "react";
import { styled } from "styled-components";
import Footer from "../Common/Footer";
import CreditRecommendationReport from "./CreditRecommendationReport";
import { callAPI, controllerAPI } from "../API/FP/Actions";
import { useNavigate } from "react-router-dom";
import { NumericFormat } from "react-number-format";
import { useAuth } from "../../context/auth";
import { contextObjForPlacingOffer } from "../Common/Constants";
import { Tooltip } from "@mui/material";
import { Toll } from "@mui/icons-material";

function FinanceRequestIndividual({
  data,
  actionDateFunction,
  creditScoreColor,
  creditScoreReportCheck,
  setCreditScoreReportCheck,
  getCreditScore,
  authToken,
}) {
  let navigate = useNavigate();

  const [interestPercentage, setInterestPercentage] = useState(
    data?.requestOffer?.interestPtg ?? 0
  );
  const [interestValue, setInterestValue] = useState(
    data?.requestOffer?.interestValue ?? 0
  );
  const [amountToBeDisbursed, setAmountToBeDisbursed] = useState(
    data?.requestOffer?.netAmount ?? 0
  );
  const [auth, setAuth] = useAuth();
  const inputRef = useRef(null);

  const authContextObj = {
    transactionDate: new Date().toISOString(),
    userId: auth?.userDetails?.username,
    tenantId: auth?.userDetails?.tenantId,
    cpCode: 3,
    clientCode: 1501,
  };

  useEffect(() => {
    inputRef.current.focus();
  }, []);

  const today = new Date();
  const yyyy = today.getFullYear();
  let mm = today.getMonth() + 1; // Months start at 0!
  let dd = today.getDate();
  let hours = today.getHours();
  let minutes = today.getMinutes();
  let seconds = today.getSeconds();
  if (hours < 10) hours = "0" + hours;
  if (minutes < 10) minutes = "0" + minutes;
  if (seconds < 10) seconds = "0" + seconds;
  if (dd < 10) dd = "0" + dd;
  if (mm < 10) mm = "0" + mm;

  const formattedToday =
    yyyy +
    "-" +
    mm +
    "-" +
    dd +
    "T" +
    hours +
    ":" +
    minutes +
    ":" +
    seconds +
    "Z";

  const [marginPtg, setMarginPtg] = useState(
    data?.requestOffer?.marginPtg || 0
  );
  const [marginValue, setMarginValue] = useState(
    data?.requestOffer?.marginValue || 0
  );
  const [amountAftMargin, setAmountAftMargin] = useState(
    data?.requestOffer?.amountAftMargin || 0
  );

  const handleMarginChange = (e) => {
    const newMarginPtg = parseFloat(e.target.value);
    setMarginPtg(newMarginPtg);
    const totalAmount = data?.requestOffer?.tradeValue;
    const newMarginValue = (totalAmount * newMarginPtg) / 100;
    const newAmountAftMargin = totalAmount - newMarginValue;

    setMarginValue(newMarginValue.toFixed(0));
    setAmountAftMargin(newAmountAftMargin.toFixed(0));

    const newInterestValue =
      (newAmountAftMargin * interestPercentage * data?.requestOffer?.term) /
      (365 * 100);
    setInterestValue(newInterestValue.toFixed(0));
    setAmountToBeDisbursed((newAmountAftMargin - newInterestValue).toFixed(0));
  };
  const isInvalidRange = marginPtg >= 15 && marginPtg <= 20;

  const isTooLow = interestPercentage < 1;
  const isTooHigh = interestPercentage > 20;
  const isInvalidRangee = interestPercentage >= 15 && interestPercentage <= 20;
  const handleInterestChange = (e) => {
    const newInterestPercentage = parseFloat(e.target.value);
    setInterestPercentage(newInterestPercentage);

    const newInterestValue =
      (amountAftMargin * newInterestPercentage * data?.requestOffer?.term) /
      (365 * 100);
    setInterestValue(newInterestValue.toFixed(0));
    setAmountToBeDisbursed((amountAftMargin - newInterestValue).toFixed(0));
  };

  const submitOffer = async () => {
    const placedOfferObj = {
      reqOffUlidId: data?.requestOffer?.reqOffUlidId,
      requestOfferRefNo: data?.requestOffer?.reqOfferRefNo,
      value: data?.requestOffer?.offerValue,
      reqAmount: data?.requestOffer?.requestAmt,
      disbursalAmount: amountToBeDisbursed,
      marginPtg: marginPtg,
      marginValue: marginValue,
      amountAftMargin: amountAftMargin,
      interestPtg: parseFloat(interestPercentage),
      term: data?.requestOffer?.term,
      financepartner: data?.requestOffer?.financepartner,
      interestValue: parseFloat(interestValue),
      netAmount: parseInt(amountToBeDisbursed),
      status: "AWAITING_ACCEPTANCE",
      offerDate: formattedToday,
      placedOfferRefNo: data?.requestOffer?.requestOfferRefNo,
      placedOfferDate: formattedToday,
      anchorTrader: data?.requestOffer?.anchorTraderName,
      tradePartner: data?.requestOffer?.tradePartnerName,
    };

    const requestOfferObj = {
      id: data?.requestOffer?.id,
      reqOffUlidId: data?.requestOffer?.reqOffUlidId,
      reqOfferRefNo: data?.requestOffer?.reqOfferRefNo,
      offerValue: data?.requestOffer?.offerValue,
      requestAmt: data?.requestOffer?.requestAmt,
      tradeValue: data?.requestOffer?.tradeValue,
      marginPtg: marginPtg,
      marginValue: marginValue,
      amountAftMargin: amountAftMargin,
      interestPtg: interestPercentage,
      term: data?.requestOffer?.term,
      interestValue: interestValue,
      netAmount: amountToBeDisbursed,
      disbursalAmount: data?.requestOffer?.disbursalAmount,
      status: "OFFER_PLACED",
      anchorTraderName: data?.requestOffer?.anchorTraderName,
      tradePartnerName: data?.requestOffer?.tradePartnerName,
      anchorTraderGst: data?.requestOffer?.anchorTraderGst,
      tradePartnerGst: data?.requestOffer?.tradePartnerGst,
      anchorTraderGSTComplianceScore:
        data?.requestOffer?.anchorTraderGSTComplianceScore,
      anchorTraderGSTERPPeerScore:
        data?.requestOffer?.anchorTraderGSTERPPeerScore,
      financerequest: data?.requestOffer?.financerequest,
      financepartner: data?.requestOffer?.financepartner,
      placedOffer: placedOfferObj,
      financeRequestDate: data?.requestOffer?.financeRequestDate,
      sellerTradePerformanceIndex:
        data?.requestOffer?.sellerTradePerformanceIndex,
    };

    const constructObj = contextObjForPlacingOffer(
      authContextObj,
      requestOfferObj,
      placedOfferObj
    );
    const placedOfferResponse = await controllerAPI(
      "post",
      auth?.token,
      constructObj,
      "on-place-offer"
    );

    navigate("/creditbazaar/placed-offers");
  };

  const preventMinus = (e) => {
    if (e.code === "Minus") {
      e.preventDefault();
    }
  };

  return (
    <>
      {!creditScoreReportCheck && (
        <div>
          <div
            style={{
              marginTop: "2%",
              marginRight: "5%",
            }}
          >
            <button
              style={{
                backgroundColor: "#4A3AFF",
                color: "white",
                border: "none",
                padding: "10px 10px",
                cursor: "pointer",
                borderRadius: "5px",
                height: "fit-content",
                fontSize: "12px",
              }}
              onClick={() => setCreditScoreReportCheck(true)}
            >
              Credit Recommendation Report
            </button>
          </div>
          <RequestInformation
            className="body-text"
            style={{ display: "flex", marginTop: "1%" }}
          >
            <div className="main-content">
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <h3 style={{ marginBottom: "5px", color: "#4A3AFF" }}>
                  Request Details
                </h3>
              </div>
              <div className="record-details">
                <table>
                  <tbody>
                    <tr>
                      <th>Action By Date</th>
                      <td>
                        {actionDateFunction(
                          new Date(data?.requestOffer?.financeRequestDate),
                          2
                        )}
                      </td>
                    </tr>
                    <tr>
                      <th>Credit Score</th>
                      <td>
                        <span
                          style={{
                            fontWeight: "700",
                          }}
                        >
                          {data?.cbcreProcess?.cumilativeTradeScore}/100
                        </span>
                      </td>
                    </tr>
                    <tr>
                      <th>Anchor Trader</th>
                      <td>{data?.requestOffer?.anchorTraderName}</td>
                    </tr>
                    <tr>
                      <th>Trade Partner</th>
                      <td>{data?.requestOffer?.tradePartnerName}</td>
                    </tr>
                    <tr>
                      <th>Total trade value (INR)</th>
                      <td>
                        <NumericFormat
                          displayType="text"
                          value={data?.requestOffer?.tradeValue}
                          thousandsGroupStyle="lakh"
                          thousandSeparator=","
                        />
                      </td>
                    </tr>
                    <tr>
                      <th>Requested Tenure (Days)</th>
                      <td>{data?.requestOffer?.term}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div className="main-content" style={{ marginLeft: "5%" }}>
              <h3 style={{ marginBottom: "5px", color: "#4A3AFF" }}>
                Final Offer Details
              </h3>
              <div className="record-details">
                <table>
                  <tbody>
                    <tr>
                      <th> Financing Margin (%)</th>
                      <td style={{ padding: "0px" }}>
                        <div>
                          <Tooltip
                            title={
                              !isInvalidRange
                                ? " Please enter a value between 15% and 20%"
                                : ""
                            }
                            open={!isInvalidRange || isTooLow || isTooHigh}
                            disableHoverListener
                            placement="top"
                            arrow
                          >
                            <input
                              type="number"
                              name="margin"
                              value={marginPtg}
                              min="1"
                              onKeyPress={preventMinus}
                              ref={inputRef}
                              onChange={handleMarginChange}
                              style={{
                                borderColor: !isInvalidRange
                                  ? "red"
                                  : "initial",
                                // width: "400px",
                              }}
                            />
                          </Tooltip>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <th>Margin value (INR)</th>
                      <td>
                        <NumericFormat
                          displayType="text"
                          value={marginValue}
                          thousandsGroupStyle="lakh"
                          thousandSeparator=","
                        />
                      </td>
                    </tr>
                    <tr>
                      <th>Amount after margin (INR)</th>
                      <td>
                        <NumericFormat
                          displayType="text"
                          value={amountAftMargin}
                          thousandsGroupStyle="lakh"
                          thousandSeparator=","
                        />
                      </td>
                    </tr>
                    <tr>
                      <th>Annualized Interest rate (%)</th>
                      <td style={{ padding: "0px" }}>
                        <div>
                          <Tooltip
                            title={
                              !isInvalidRangee
                                ? " Please enter a value between 15% and 20%"
                                : ""
                            }
                            open={!isInvalidRangee || isTooLow || isTooHigh}
                            disableHoverListener
                            placement="top"
                            arrow
                          >
                            <input
                              type="number"
                              name="interest"
                              value={interestPercentage}
                              min="1"
                              onKeyPress={preventMinus}
                              ref={inputRef}
                              onChange={handleInterestChange}
                              style={{
                                borderColor:
                                  !isInvalidRangee || isTooLow || isTooHigh
                                    ? "red"
                                    : "initial",
                                // width: "400px",
                                boxSizing: "border-box",
                                // position: "relative",
                                zIndex: 1,
                              }}
                            />
                          </Tooltip>
                        </div>
                      </td>{" "}
                    </tr>
                    <tr>
                      <th>Interest value (INR)</th>
                      <td>
                        <NumericFormat
                          displayType="text"
                          value={interestValue}
                          thousandsGroupStyle="lakh"
                          thousandSeparator=","
                        />
                      </td>
                    </tr>
                    <tr>
                      <th>Amount to be disbursed (INR)</th>
                      <td>
                        <NumericFormat
                          displayType="text"
                          value={amountToBeDisbursed}
                          thousandsGroupStyle="lakh"
                          thousandSeparator=","
                        />
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </RequestInformation>
          <div style={{ marginTop: "30px", textAlign: "center" }}>
            <button
              style={{
                backgroundColor: "#4A3AFF",
                color: "white",
                border: "none",
                padding: "10px 20px",
                cursor: "pointer",
                borderRadius: "5px",
                height: "fit-content",
                opacity:
                  interestPercentage > 0 &&
                  marginPtg > 0 &&
                  isInvalidRange &&
                  isInvalidRangee
                    ? "1"
                    : "0.5",
              }}
              disabled={
                interestPercentage > 0 &&
                marginPtg > 0 &&
                isInvalidRange &&
                isInvalidRangee
                  ? false
                  : true
              }
              onClick={submitOffer}
            >
              Place an Offer
            </button>
          </div>
        </div>
      )}
      {creditScoreReportCheck && (
        <CreditRecommendationReport
          creditScoreReportCheck={creditScoreReportCheck}
          setCreditScoreReportCheck={setCreditScoreReportCheck}
          financeRequestData={data}
          authToken={authToken}
        />
      )}
      <div style={{ marginTop: "30vh" }}>
        <Footer />
      </div>
    </>
  );
}

export default FinanceRequestIndividual;

const RequestInformation = styled.div`
  .content {
    display: flex;
    margin-top: 20px;
    padding: 20px;
  }
  .main-content {
    width: 45%;
  }

  .table-container {
    margin-top: 20px;
  }
  table {
    width: 100%;
    border-collapse: collapse;
    border-left: 1px solid rgba(0, 0, 128, 0.2);
    border-right: 1px solid rgba(0, 0, 128, 0.2);
    border-top: 1px solid rgba(0, 0, 128, 0.2);
    // color: #4a3aff;
    border-radius: 2px;

    border-style: hidden; /* hide standard table (collapsed) border */
    box-shadow: 0 0 0 1px rgba(0, 0, 128, 0.2);
  }
  table tr:last-child td:first-child {
    // border: 2px solid orange;
    // border-bottom-left-radius: 10px;
  }

  table tr:last-child td:last-child {
    // border: 2px solid green;
    // border-bottom-right-radius: 10px;
    // border-radius: 0px 0px 0px 10px;
  }
  th,
  td {
    padding: 7px;
    text-align: left;
    border-bottom: 1px solid rgba(0, 0, 128, 0.2);
  }
  th {
    // background-color: rgba(0, 0, 128, 0.2);
    // font-weight: bold;
    color: rgba(97, 94, 131, 1);
    border-right: 1px solid rgba(0, 0, 128, 0.2);
    width: 55%;
  }
  table input {
    width: 100%;
    padding: 9px 0px 9px 5px;
    font-size: 14px;
  }
  input,
  textarea {
    border: 1px solid rgba(0, 0, 128, 0.2);
    background-color: rgba(229, 234, 252, 0.3);
  }
`;
