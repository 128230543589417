import {
  Alert,
  Box,
  Checkbox,
  CircularProgress,
  Input,
  Snackbar,
  TextField,
} from "@mui/material";
import { inputLabelClasses } from "@mui/material/InputLabel";

import React, { useCallback, useEffect, useState } from "react";
import Modal from "../../Common/Modal";
import UpdatesIframe from "../../Common/UpdatesIframe";
import {
  kycContextObjController,
  profileContextObjController,
} from "../../Common/Constants";
import { controllerAPI } from "../../API/FP/Actions";
import { useAuth } from "../../../context/auth";

function ProfileDetails({
  profileDetailsApiData,
  presignedURl,
  isLoading,
  setIsLoading,
  firmMemberDetailsList,
  allTypesOfFirmsData,
}) {
  const label = { inputProps: { "aria-label": "Checkbox demo" } };

  const [modalOpen, setModalOpen] = useState(false);

  const [personName, setPersonName] = useState("");
  const [personMobileNumber, setPersonMobileNumber] = useState("");
  const [personEmail, setPersonEmail] = useState("");
  const [personCompanyName, setPersonCompanyName] = useState("");
  const [personCompanyAddress, setPersonCompanyAddress] = useState("");

  const [acceptDeclaration, setAcceptDeclaration] = useState(false);
  const [acceptDeclarationcheck, setAcceptDeclarationcheck] = useState(false);
  const [acceptTerms, setAcceptTerms] = useState(false);
  const [acceptTerm, setAcceptTerm] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [apiResultMessage, setApiResultMessage] = useState("");
  const [alertSeverity, setAlertSeverity] = useState("");

  const [auth, setAuth] = useAuth();

  const authContextObj = {
    transactionDate: new Date().toISOString(),
    userId: auth?.userDetails?.username,
    tenantId: auth?.userDetails?.tenantId,
    cpCode: "1",
  };

  const [anchorTraderID, setAnchorTraderID] = useState(
    auth?.userDetails?.personaIds["ANCHOR_TRADER-1"]
  );
  // console.log("1111111 AUUUUUUUU AT", auth, anchorTraderID);
  const CloseModal = () => {
    setModalOpen(false);
  };
  useEffect(() => {
    const list = document.getElementsByClassName("box-mainHeader")[0];
    if (modalOpen) {
      list.style.overflow = "hidden";
    } else {
      list.style.overflow = "auto";
    }
  }, [modalOpen]);

  const saveProfileDetails = async () => {
    const profileObj = {
      id: profileDetailsApiData?.id,
      atId: parseInt(anchorTraderID),
      atUlidId: profileDetailsApiData?.atUlidId,
      orgId: profileDetailsApiData?.orgId,
      // tenantId: auth?.userDetails?.tenantId,
      tenantId: profileDetailsApiData?.tenantId,
      customerName: personName,
      orgName: personCompanyName,
      gstId: profileDetailsApiData?.gstId,
      phoneNumber: personMobileNumber,
      anchorTraderName: personName,
      location: personCompanyAddress,
      anchorTraderGST: profileDetailsApiData?.anchorTraderGST,
      anchorTraderSector: profileDetailsApiData?.anchorTraderSector,
      anchorTraderPAN: profileDetailsApiData?.anchorTraderPAN,
      kycCompleted: profileDetailsApiData?.kycCompleted,
      bankDetails: profileDetailsApiData?.bankDetails,
      emailId: profileDetailsApiData?.emailId,
      personalEmailId: personEmail,
      erpAccess: profileDetailsApiData?.erpAccess,
      typeOfFirm: profileDetailsApiData?.typeOfFirm,
      panStatus: profileDetailsApiData?.panStatus,
      tosDocument: profileDetailsApiData?.tosDocument,
      acceptTerms: acceptTerms,

      acceptDeclaration: acceptDeclaration,
      acceptDeclarationcheck: acceptDeclarationcheck,
      gstRegistrationCertificateUploadStatus:
        profileDetailsApiData?.gstRegistrationCertificateUploadStatus,
      gstRegistrationCertificateVerificationStatus:
        profileDetailsApiData?.gstRegistrationCertificateVerificationStatus,
      udhyamRegistrationcertificateUploadStatus:
        profileDetailsApiData?.udhyamRegistrationcertificateUploadStatus,
      udhyamRegistrationcertificateVerificationStatus:
        profileDetailsApiData?.udhyamRegistrationcertificateVerificationStatus,
      kycDeclaration: profileDetailsApiData?.kycDeclaration,
    };

    setIsLoading(true);
    try {
      const constructObj = kycContextObjController(
        authContextObj,
        anchorTraderID,
        profileObj,
        firmMemberDetailsList,
        allTypesOfFirmsData
      );
      const saveProfileDetailsResponse = await controllerAPI(
        "post",
        auth?.token,
        constructObj,
        "update-at-profile-details"
      );

      if (saveProfileDetailsResponse.res.status === 200) {
        setOpenSnackbar(true);
        setApiResultMessage("Saved Details Successfully");
        setAlertSeverity("success");
      }
    } catch (err) {
      console.log("Error", err);
      setOpenSnackbar(true);
      setApiResultMessage("Error Occured. Please try later");
      setAlertSeverity("error");
    } finally {
      setIsLoading(false);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    saveProfileDetails();
  };

  useEffect(() => {
    setPersonName(profileDetailsApiData?.anchorTraderName);
    setPersonMobileNumber(profileDetailsApiData?.phoneNumber);
    setPersonEmail(profileDetailsApiData?.personalEmailId);
    setPersonCompanyName(profileDetailsApiData?.orgName);
    setPersonCompanyAddress(profileDetailsApiData?.location);
    setAcceptDeclaration(profileDetailsApiData?.acceptDeclaration);
    setAcceptDeclarationcheck(profileDetailsApiData?.acceptDeclaration);
    setAcceptTerms(profileDetailsApiData?.acceptTerms);
    setAcceptTerm(profileDetailsApiData?.acceptTerms);
  }, [profileDetailsApiData, presignedURl]);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenSnackbar(false);
  };
  const [universalclick, setuniversalclick] = useState(false);
  const editbtnclick = () => {
    setuniversalclick(true);
  };

  const cancelclick = () => {
    setuniversalclick(false);
  };
  return (
    <div
      className="body-text"
      style={{ paddingTop: "0px", display: "flex", flexDirection: "row" }}
    >
      {modalOpen ? (
        <Modal
          bgcolor={"#EEEEEE"}
          ordered={modalOpen}
          left={"3%"}
          modelClosed={CloseModal}
        >
          <UpdatesIframe
            closefunction={CloseModal}
            updateUrl={presignedURl}
            updateName={"updateName"}
            video={false}
          />
        </Modal>
      ) : null}
      <form
        className="form"
        onSubmit={handleSubmit}
        style={{ width: "1200px" }}
      >
        <div style={{ display: "flex", flexDirection: "column" }}>
          <div style={{ display: "flex", flexDirection: "row" }}>
            <div style={{ color: "#000", fontWeight: "bold" }}>
              Personal Details
            </div>
            <div style={{ width: "100%", display: "flex", flexWrap: "wrap" }}>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  Width: "250px",
                  margin: "5px",
                }}
              >
                <TextField
                  inputProps={{ style: { fontSize: 14 } }}
                  InputLabelProps={{
                    sx: {
                      color: "#4A3AFF",
                      fontWeight: "bold",
                      [`&.${inputLabelClasses.shrink}`]: {
                        color: "#4A3AFF",
                      },
                    },
                  }}
                  id="standard-basic"
                  label="Name"
                  value={personName}
                  onChange={(e) => setPersonName(e.target.value)}
                  variant="outlined"
                  required
                  disabled={universalclick !== true}
                />
              </div>

              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  Width: "250px",
                  margin: "5px",
                }}
                className="removeArrow"
              >
                <TextField
                  inputProps={{ style: { fontSize: 14, maxLength: 10 } }}
                  InputLabelProps={{
                    sx: {
                      color: "#4A3AFF",
                      fontWeight: "bold",
                      [`&.${inputLabelClasses.shrink}`]: {
                        color: "#4A3AFF",
                      },
                    },
                  }}
                  id="standard-basic"
                  label="Phone number"
                  maxLength={10}
                  value={personMobileNumber}
                  onChange={(e) => setPersonMobileNumber(e.target.value)}
                  variant="outlined"
                  type="number"
                  required
                  disabled={universalclick !== true}
                />
              </div>

              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  Width: "250px",
                  margin: "5px",
                }}
              >
                <TextField
                  inputProps={{ style: { fontSize: 14 } }}
                  InputLabelProps={{
                    sx: {
                      color: "#4A3AFF",
                      fontWeight: "bold",
                      [`&.${inputLabelClasses.shrink}`]: {
                        color: "#4A3AFF",
                      },
                    },
                  }}
                  id="standard-basic"
                  label="Email address"
                  value={personEmail}
                  onChange={(e) => setPersonEmail(e.target.value)}
                  variant="outlined"
                  required
                  disabled={universalclick !== true}
                />
              </div>
            </div>
          </div>

          <div
            style={{
              display: "flex",
              marginTop: "3%",
              flexDirection: "row",
            }}
          >
            <div style={{ color: "#000", fontWeight: "bold" }}>
              Business details
            </div>
            <div
              style={{
                width: "100%",
                display: "flex",
                flexWrap: "wrap",
                margin: "5px",
              }}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  width: "200px",
                  margin: "5px",
                }}
              >
                <TextField
                  inputProps={{ style: { fontSize: 14 } }}
                  InputLabelProps={{
                    sx: {
                      color: "#4A3AFF",
                      fontWeight: "bold",
                      [`&.${inputLabelClasses.shrink}`]: {
                        color: "#4A3AFF",
                      },
                    },
                  }}
                  id="standard-basic"
                  label="Company name"
                  value={personCompanyName}
                  onChange={(e) => setPersonCompanyName(e.target.value)}
                  variant="outlined"
                  required
                  disabled={universalclick !== true}
                />
              </div>

              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  width: "500px",
                  margin: "5px",
                }}
              >
                <TextField
                  id="standard-multiline-static"
                  label="Company address"
                  InputLabelProps={{
                    sx: {
                      color: "#4A3AFF",
                      fontWeight: "bold",
                      [`&.${inputLabelClasses.shrink}`]: {
                        color: "#4A3AFF",
                      },
                    },
                  }}
                  multiline
                  value={personCompanyAddress}
                  onChange={(e) => setPersonCompanyAddress(e.target.value)}
                  inputProps={{ style: { fontSize: 14, width: "100%" } }}
                  variant="outlined"
                  required
                  disabled={universalclick !== true}
                  fullWidth
                />
              </div>
            </div>
          </div>

          <h4 style={{ marginBottom: "0px", marginTop: "60px" }}>
            <Checkbox
              {...label}
              checked={acceptTerms}
              disabled={acceptTerm}
              onChange={() => setAcceptTerms(!acceptTerms)}
              required
            />
            I have read and accepted the{" "}
            <span
              style={{ color: "#4A3AFF", cursor: "pointer" }}
              onClick={() => setModalOpen(true)}
            >
              Terms and Conditions
            </span>
            .
          </h4>
          <h4 style={{ marginBottom: "5px", marginTop: "0px" }}>
            <Checkbox
              {...label}
              checked={acceptDeclaration}
              disabled={acceptDeclarationcheck}
              onChange={() => setAcceptDeclaration(!acceptDeclaration)}
              required
            />
            I hereby declare that the information given above is true to the
            best of my knowledge and belief.
          </h4>
          <div style={{ marginTop: "15px", textAlign: "center" }}>
            <button
              type="submit"
              style={{
                borderRadius: "2px",
                backgroundColor: "#4A3AFF",
                color: "white",
                border: "none",
                padding: "10px 20px",
                cursor: "pointer",
                height: "fit-content",
                boxShadow: "0px 3px 5px 0px rgba(0, 0, 0, 0.10)",
                opacity: universalclick !== true ? "0.5" : "1",
              }}
              disabled={universalclick !== true}
            >
              Save Details
            </button>
          </div>
        </div>
      </form>
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
        }}
      >
        <button
          style={{
            borderRadius: "5px",
            backgroundColor: "#4A3AFF",
            color: "white",
            border: "none",
            padding: "8px 8px",
            cursor: "pointer",
            height: "fit-content",
            boxShadow: "0px 3px 5px 0px rgba(0, 0, 0, 0.10)",
            opacity: universalclick ? "0.5" : "1",
            width: "90px",
            marginRight: universalclick ? "10px" : "0",
          }}
          onClick={editbtnclick}
        >
          Edit Details
        </button>

        {universalclick && (
          <button
            style={{
              borderRadius: "5px",
              backgroundColor: "#4A3AFF",
              color: "white",
              border: "none",
              padding: "8px 8px",
              cursor: "pointer",
              height: "fit-content",
              boxShadow: "0px 3px 5px 0px rgba(0, 0, 0, 0.10)",
            }}
            onClick={cancelclick}
          >
            Cancel
          </button>
        )}
      </div>
      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={handleClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        <Alert
          onClose={handleClose}
          severity={alertSeverity}
          sx={{ width: "100%" }}
        >
          {apiResultMessage}
        </Alert>
      </Snackbar>
    </div>
  );
}

export default ProfileDetails;
