import { React, useState } from "react";
import styled from "styled-components";
import { Link, useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { useAuth } from "../../context/auth";
import Footer from "../Common/Footer";

function Signin() {
  const [role, setRole] = useState("seller");
  const [auth, setAuth] = useAuth();

  const navigate = useNavigate();
  const [username, setUsername] = useState("");
  const [organizationID, setOrganizationID] = useState("");
  const [password, setPassword] = useState("");

  //routing
  const handleNavigate = (res) => {
    setRole(res?.data?.defaultPersona);
    if (res?.data?.defaultPersona === "ANCHOR_TRADER") {
      navigate("/creditbazaar/anchortrader-profile");
    } else if (res?.data?.defaultPersona === "TRADE_PARTNER") {
      navigate("/creditbazaar/buyer-request-information");
    } else if (res?.data?.defaultPersona === "FINANCE_PARTNER") {
      navigate("/creditbazaar/offer-information");
    } else {
      navigate("/creditbazaar/offer-information");
    }
  };

  ///login
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      delete axios.defaults.headers.common["Authorization"];
      const res = await axios.post(
        `${process.env.REACT_APP_API}/api/tenant/signin`,
        {
          username,
          password,
        },
        {
          headers: {
            "Content-Type": "application/json",
            "X-TenantID": `${organizationID}`,
          },
        }
      );
      // console.log(res.data);
      if (res.status === 200) {
        // alert(res.data.message);
        setAuth({
          ...auth,
          user: res ? res.data.username : localStorage.getItem("auth").user,
          userDisplayName: res && res.data.companyName,
          token: res
            ? res.data.accessToken
            : localStorage.getItem("auth").token,
          userDetails: res.data,
        });
        // console.log({ auth });
        localStorage.setItem("auth", JSON.stringify(res.data));
        console.log("successfull");
        handleNavigate(res);
      } else {
        // alert(res.data.message);
        // setAuth({
        //   ...auth,
        //   user: res.data.username,
        //   token: res.data.accessToken,
        // });
        // // console.log({ auth });
        // localStorage.setItem("auth", JSON.stringify(res.data));
        // console.log("Unsuccessfull");
        // handleNavigate(res);
      }
    } catch (error) {
      console.log(error);
      alert("something went wrong");
    }
  };
  console.log({ auth });

  return (
    <>
      <Sign1>
        <div className="formwrap">
          <div className="content">Welcome to Credit Bazaar</div>
          <div className="small-content">Please login to your account</div>
          <form className="form" onSubmit={handleSubmit}>
            <label htmlFor="username" style={{ marginLeft: "-195px" }}>
              Organization ID
            </label>
            <input
              type="txt"
              value={organizationID}
              onChange={(e) => setOrganizationID(e.target.value)}
              id="orgid"
              name="organizationId"
              placeholder="Enter Organization ID  "
              required
            />
            <label htmlFor="username" style={{ marginLeft: "-235px" }}>
              Username
            </label>
            <input
              type="txt"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              id="username"
              name="username"
              placeholder="Enter Username"
              required
            />
            <label htmlFor="name" style={{ marginLeft: "-240px" }}>
              Password
            </label>
            <input
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              id="password"
              name="password"
              placeholder="Enter Password"
              required
            />

            <div
              style={{
                marginBottom: "15px",
                width: "100%",
                textAlign: "end",
              }}
            >
              {/* <Link to={"/forgotpassword"}>Forgot Password ?</Link> */}
            </div>

            <button type="submit">Log In</button>
          </form>

          {/* <div>
            Don't have an account?
            <Link to="/signup">Signup for free</Link>
          </div> */}
        </div>
      </Sign1>
      {/* <div style={{ marginTop: "20vh" }}> */}
      {/* <Footer /> */}
      {/* </div> */}
    </>
  );
}

export default Signin;

const Sign1 = styled.div`
  display: flex;
  justify-content: center;

  /* Styles for the page background */
  body {
    background-color: #e5e4e2;
  }

  /* Styles for the form wrapper */
  .formwrap {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 40px;
    background-color: white;
    padding: 50px;
    border-radius: 5px;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.3);
  }

  /* Styles for the content */
  .content {
    font-size: 18px;
    font-weight: bold;
    color: #333333;
    margin-bottom: 5px;
    margin-left: 25px;
  }
  .small-content {
    font-size: 16px;
    // font-weight: bold;
    color: #333333;
    margin-bottom: 30px;
    margin-left: 25px;
  }

  /* Styles for the form */
  .form {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  /* Styles for the form labels */
  label {
    font-weight: bold;
    margin-bottom: 5px;
    color: #333333;
  }

  /* Styles for the form inputs */
  input[type="txt"],
  input[type="password"] {
    height: 30px;
    width: 300px;
    margin-bottom: 20px;
    padding: 5px;
  }

  /* Styles for the submit button */
  button[type="submit"] {
    font-size: 16px;
    color: white;
    height: 40px;
    width: 100%;
    margin-bottom: 20px;
    background-color: orange;
    border: none;
    border-radius: 25px;
    cursor: pointer;
  }

  /* Styles for the "Don't have an account?" and "Forgot Password?" links */
  div > a {
    margin-top: 20px;
    text-decoration: none;
    color: #333333;
  }

  /* Optional hover effect for links */
  div > a:hover {
    text-decoration: underline;
  }
`;
