import React, { useEffect, useState } from "react";
import { styled } from "styled-components";
import Footer from "../Common/Footer";
import { useNavigate } from "react-router-dom";
import {
  Breadcrumbs,
  CircularProgress,
  Link,
  Tooltip,
  Typography,
} from "@mui/material";
import { callAPI } from "../API/FP/Actions";
import { dateFormat, requestStates } from "../Common/Constants";
import { NumericFormat } from "react-number-format";
import { useAuth } from "../../context/auth";
import { contextObj } from "../Common/Constants";
import Pagenotfound from "../Common/pagenotfound";

function RequestLogs() {
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  // const [datafinance, setdatafinance] = useState([]);
  const [authToken, setAuthToken] = useState("");
  const [auth, setAuth] = useAuth();
  const [financePartnerID, setFinancePartnerID] = useState(
    auth?.userDetails?.personaIds["FINANCE_PARTNER-3"]
  );
  const [isLoading, setIsLoading] = useState(false);
  const [currentRequestDetails, setCurrentRequestDetails] = useState({});
  //pagenation
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(5);
  const [totalPages, setTotalPage] = useState(0);
  // Math.ceil(data.length / pageSize)
  const lastindex = currentPage * pageSize;
  const firstindex = lastindex - pageSize;
  const recordss = data.slice(firstindex, lastindex);
  const numbers = [...Array(totalPages + 1).keys()].slice(1);

  const authContextObj = {
    transactionDate: new Date().toISOString(),
    userId: auth?.userDetails?.username,
    tenantId: auth?.userDetails?.tenantId,
    personaCode: 1,
    clientCode: 1501,
  };

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        // const oResponse = await getOpenRequests(auth?.token);
        const constructObj = contextObj(authContextObj, financePartnerID);
        const placedOffersResponse = await callAPI(
          auth?.token,
          constructObj,
          `get-fp-placed-offer?page=${
            currentPage - 1
          }&size=${pageSize}&sort=id,desc`
        );
        //hi
        if (placedOffersResponse?.response?.status === 401) {
          setAuth({
            ...auth,
            user: null,
            token: "",
            userDisplayName: "",
            userDetails: null,
          });
          localStorage.removeItem("auth");
          navigate("/creditbazaar/");
        }

        if (placedOffersResponse.res.status === 200) {
          setData(placedOffersResponse.res.data.data);
          console.log(
            "Total records",
            placedOffersResponse?.res?.data?.totalElements
          );
          setTotalPage(
            Math.ceil(placedOffersResponse?.res?.data?.totalElements / pageSize)
          );
          // setdatafinance(placedOffersResponse.res.data.data.financeRequest);
        }
      } catch (err) {
        console.log("Error fetching data:", err);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [currentPage]);

  const creditScoreColor = (score) => {
    if (score > 7) {
      return "#00692A";
    } else {
      return "#D20319";
    }
  };
  const handlePrevPage = () => {
    if (currentPage > 1) setCurrentPage(currentPage - 1);
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) setCurrentPage(currentPage + 1);
  };

  const ChangePage = (pageNumber) => () => setCurrentPage(pageNumber);
  console.log("11111111111111111 recordsss", data);
  return (
    <>
      {isLoading && (
        <CircularProgress
          style={{ position: "absolute", left: "50%", top: "50%" }}
        />
      )}
      <Finance1>
        <div>
          <div
            role="presentation"
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              color: "#4A3AFF",
              marginTop: "50px",
            }}
          >
            <Breadcrumbs aria-label="breadcrumb">
              {/* <Link
                underline="hover"
                color="rgba(74, 58, 255, 0.5)"
                onClick={() => {
                  navigate("/creditbazaar/financesdashboard");
                }}
                className="custom-link clickable"
              >
                Dashboard
              </Link> */}
              <Typography color="#4A3AFF"> </Typography>
            </Breadcrumbs>

            <div className="header">
              <h3 style={{ margin: "0px" }} className="section-heading">
                Placed Offers
              </h3>
              {/* <Typography>
                List of participated finance requests are below
              </Typography> */}
            </div>
          </div>
        </div>
      </Finance1>

      <Dash className="body-text">
        <div className="main-content">
          <div className="record-details">
            {data?.length === 0 && !isLoading ? (
              <>
                <table>
                  <tbody>
                    <tr>
                      <th>Request ID</th>
                      <th>Placed Offer Date</th>
                      <th>Anchor Trader</th>
                      <th>Trade Partner</th>
                      <th>Disbursal amount (INR) </th>
                      <th>Status</th>
                    </tr>
                  </tbody>
                </table>
                <Pagenotfound />
              </>
            ) : (
              <table>
                <tbody>
                  <tr>
                    <th>Request ID</th>
                    <th>Placed Offer Date</th>
                    <th>Anchor Trader</th>
                    <th>Trade Partner</th>
                    <th>Disbursal amount (INR) </th>
                    <th>Status</th>
                  </tr>
                  {data
                    .sort((a, b) => b.id - a.id)
                    .map((val, ind) => (
                      <tr key={ind}>
                        <td>
                          <span
                            style={{
                              cursor: "pointer",
                              textDecoration: "underline",
                            }}
                            onClick={() => {
                              navigate(
                                `/creditbazaar/placed-offers/${val?.placedOffer?.placedOfferRefNo}`,
                                {
                                  state: {
                                    id: val?.financeRequest
                                      ?.financeRequestUlidId,
                                    refno: val?.placedOffer?.placedOfferRefNo,
                                    requestData: val?.placedOffer,
                                    authToken: authToken,
                                  },
                                }
                              );
                            }}
                          >
                            {val?.placedOffer?.placedOfferRefNo}
                          </span>
                        </td>
                        {/* <td>
                        <span
                          style={{
                            color: creditScoreColor(val?.creditScore),
                            fontWeight: "700",
                          }}
                        >
                          {val?.creditScore}/10
                        </span>
                      </td> */}
                        <td>
                          {dateFormat(new Date(val?.placedOffer?.offerDate), 0)}
                        </td>
                        <td>
                          {val?.placedOffer?.anchorTrader?.length > 10 ? (
                            <Tooltip
                              title={`${val?.placedOffer?.anchorTrader}`}
                              placement="top"
                            >
                              <div
                                className={
                                  `${val?.placedOffer?.anchorTrader}`?.length >
                                    20 && `text-ellipse`
                                }
                              >
                                {/*val?.anchorTrader*/}
                                {val?.placedOffer?.anchorTrader}
                              </div>
                            </Tooltip>
                          ) : (
                            <div
                              className={
                                `${val?.placedOffer?.anchorTrader}`?.length >
                                  20 && `text-ellipse`
                              }
                            >
                              {val?.placedOffer?.anchorTrader}
                            </div>
                          )}
                        </td>
                        <td>
                          {val?.placedOffer?.tradePartner?.length > 20 ? (
                            <Tooltip
                              title={`${val?.placedOffer?.tradePartner}`}
                              placement="top"
                            >
                              <div
                                className={
                                  `${val?.placedOffer?.tradePartner}`?.length >
                                    20 && `text-ellipse`
                                }
                                style={{ width: "120px" }}
                              >
                                {val?.placedOffer?.tradePartner}
                              </div>
                            </Tooltip>
                          ) : (
                            <div
                              className={
                                `${val?.placedOffer?.tradePartner}`?.length >
                                  20 && `text-ellipse`
                              }
                              style={{ width: "120px" }}
                            >
                              {val?.placedOffer?.tradePartner}
                            </div>
                          )}
                        </td>
                        <td>
                          <NumericFormat
                            displayType="text"
                            value={val?.placedOffer?.netAmount}
                            thousandsGroupStyle="lakh"
                            thousandSeparator=","
                          />
                        </td>
                        <td>
                          <span
                            style={{
                              backgroundColor:
                                requestStates(val?.placedOffer?.status) ===
                                "Awaiting Disbursement"
                                  ? "rgb(210, 3, 25)"
                                  : "rgba(216, 247, 230, 1)",
                              color:
                                requestStates(val?.placedOffer?.status) ===
                                "Awaiting Disbursement"
                                  ? "white"
                                  : "rgba(0, 105, 42, 1)",
                              padding: "5px 10px 5px 10px",
                              border:
                                requestStates(val?.placedOffer?.status) ===
                                "Awaiting Disbursement"
                                  ? "1px solid rgb(210, 3, 25)"
                                  : "1px solid rgba(216, 247, 230, 1)",
                              borderRadius: "20px",
                            }}
                          >
                            {requestStates(val?.placedOffer?.status)}
                          </span>
                        </td>
                        {/* <td>
                        <button
                          style={{
                            borderRadius: "2px",
                            backgroundColor: "#4A3AFF",
                            color: "white",
                            border: "none",
                            padding: "5px 5px",
                            cursor: "pointer",
                          }}
                          onClick={() =>
                            navigate(
                              `/finance_request_information/${val.requestId}`,
                              {
                                state: { id: val.requestId },
                              }
                            )
                          }
                        >
                          View request
                        </button>
                      </td> */}
                      </tr>
                    ))}
                </tbody>
              </table>
            )}
            {data.length !== 0 && (
              <>
                <nav
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-end",
                    width: "100%",
                  }}
                >
                  <ul
                    style={{
                      display: "flex",
                      listStyleType: "none",
                      padding: 0,
                      margin: 0,
                    }}
                  >
                    <li style={{ marginRight: "8px" }}>
                      <p
                        style={{
                          padding: "8px 12px",
                          borderRadius: "4px",
                          cursor: currentPage === 1 ? "not-allowed" : "pointer",
                          backgroundColor:
                            currentPage === 1 ? "#f0f0f0" : "white",
                        }}
                        onClick={handlePrevPage}
                      >
                        {"<"}
                      </p>
                    </li>
                    {/* Showing current page and total pages information */}
                    <li
                      style={{
                        marginRight: "8px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        fontSize: "14px",
                      }}
                    >
                      Showing {currentPage} of {totalPages}
                    </li>
                    <li style={{ marginRight: "8px" }}>
                      <p
                        style={{
                          padding: "8px 12px",
                          borderRadius: "4px",
                          cursor:
                            currentPage === totalPages
                              ? "not-allowed"
                              : "pointer",
                          backgroundColor:
                            currentPage === totalPages ? "#f0f0f0" : "white",
                        }}
                        onClick={handleNextPage}
                      >
                        {">"}
                      </p>
                    </li>
                  </ul>
                </nav>
              </>
            )}
          </div>
        </div>
      </Dash>
      <div style={{ marginTop: "30vh" }}>
        <Footer />
      </div>
    </>
  );
}

export default RequestLogs;

const Finance1 = styled.div`
  .custom-link:hover {
    color: #4a3aff;
  }
  .btn {
    color: white;
    background-color: #007bff;
    padding: 10px 20px;
    text-decoration: none;
    border-radius: 5px;
    display: inline-block;
    margin-left: 20px;
    font-size: 20px;
    // margin-top: 20vh;
  }
  .btn1 {
    color: white;
    background-color: orange;
    padding: 10px 20px;
    text-decoration: none;
    font-size: 20px;
    border-radius: 5px;
    display: inline-block;
    margin-left: 20px;
  }
  .btn:hover {
    background-color: #0056b3;
  }

  .content {
    margin-left: 20px;
    margin-top: 20px;
    display: flex;
  }
  .offer-card {
    border: 2px solid black;
    // width: 170%;
    margin-bottom: 20px;
  }
  .lender-details {
    margin-left: 40px;
  }

  .header {
    font-size: 18px;
    margin-top: 15px;
  }

  .table-container {
    margin-top: 20px;
  }
  table {
    // width: 300%;
    border-collapse: collapse;
    border-left: 1px solid #dddddd;
    border-right: 1px solid #dddddd;
    border-top: 1px solid #dddddd;
  }
  th,
  td {
    padding: 10px;
    text-align: left;
    border-bottom: 1px solid #dddddd;
  }
  th {
    background-color: #f8f9fa;
    font-weight: bold;
  }
  .search-bar {
    display: flex;
    margin-bottom: 20px;
    margin-right: 10px;
  }
  .search-input {
    flex: 1;
    padding: 8px;
    border: 1px solid #dddddd;
    border-radius: 4px 0 0 4px;
    font-size: 14px;
    align-items: center;
  }
  .search-button {
    background-color: #007bff;
    color: #ffffff;
    border: none;
    padding: 8px;
    border-radius: 0 4px 4px 0;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  .search-button:hover {
    background-color: #0056b3;
  }
  .popup {
    position: relative;
    display: inline-block;
    cursor: pointer;
  }
  .popup .popuptext {
    width: 700px;
    visibility: hidden;
    background-color: #fff;
    color: #000;
    text-align: center;
    border-radius: 6px;
    padding: 8px 0;
    position: absolute;
    z-index: 1;
    left: 50%;
    margin-left: -80px;
    overflow-y: auto;
    border-width: 3px;
    border-style: solid;
    border-color: #000;
  }
  .popup .popuptext::after {
    content: "";
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: #555 transparent transparent transparent;
  }
  .popup .show {
    visibility: visible;
    -webkit-animation: fadeIn 1s;
    animation: fadeIn 1s;
  }
  .popup .popuptext .btn {
    visibility: hidden;
  }
  .btn5 {
    color: white;
    background-color: #007bff;
    padding: 10px 20px;
    text-decoration: none;
    border-radius: 5px;
    display: inline-block;
    margin-left: 65vh;
    margin-bottom: 15px;
  }
  .btn5:hover {
    background-color: #0056b3;
  }
  @-webkit-keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
`;

const Dash = styled.div`
  body {
    font-family: Arial, sans-serif;
    margin: 0;
    padding: 0;
  }

  .content {
    display: flex;
    margin-top: 20px;
  }

  .main-content {
    display: flex;
    padding: 20px 0px 10px 0px;
    flex-direction: column;
  }
  .table-container {
    margin-top: 20px;
  }
  table {
    width: 100%;
    border-collapse: collapse;
    // border-left: 1px solid rgba(0, 0, 128, 0.2);
    // border-right: 1px solid rgba(0, 0, 128, 0.2);
    border-top: 1px solid rgba(0, 0, 128, 0.2);
    // color: #4a3aff;
    border-radius: 5px;

    border-style: hidden; /* hide standard table (collapsed) border */
    box-shadow: 0 0 0 1px rgba(0, 0, 128, 0.2);
  }
  table tr:last-child td:first-child {
    // border: 2px solid orange;
    // border-bottom-left-radius: 10px;
  }

  table tr:last-child td:last-child {
    // border: 2px solid green;
    // border-bottom-right-radius: 10px;
    // border-radius: 0px 0px 0px 10px;
  }
  th,
  td {
    padding: 10px;
    text-align: left;
    border-bottom: 1px solid rgba(0, 0, 128, 0.2);
  }
  th {
    // background-color: rgba(0, 0, 128, 0.2);
    // font-weight: bold;
    color: rgba(97, 94, 131, 1);
    background: rgba(74, 58, 255, 0.1);
  }
  .record-details {
  }
`;
